import React, { useEffect, useState } from 'react';
import SortPhotos from './SortPhotos'
import Modal from '../entries/modal'

const PhotosPopup = ({ hit = false, opened = false, setCar, afterSave }) => {
  let [photos, setPhotos] = useState([])
  let [updatedPhotos, setUpdatedPhotos] = useState([])
  let [loadingPhotos, setLoadingPhotos] = useState(false)
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    if (opened) {
      setLoadingPhotos(true)
      fetch(`/cars/${hit.id}.json`)
        .then(res => res.json())
        .then(data => {
          setPhotos(data.images);
          setLoadingPhotos(false)
        })
    }
  }, [opened]);

  const sendPhotosToServer = () => {
    if (updatedPhotos !== photos) {
      setLoading(true)
      fetch(`/cars/${hit.slug}/photos/sort`, {
        method: 'PATCH',
        body: JSON.stringify({
          photo: updatedPhotos.map(item => item.id),
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }).then(res => res.json())
        .then(data => {
          setLoading(false);
          setPhotos([]);
          if (hit) {
            setCar({ ...hit, primary_image_url: data.images[0].image.thumb.url });
          }
          $(`#model-${hit.id}`).modal('hide')
          afterSave()
        })
    }
  }

  return (
    <Modal
      id={`model-${hit.id}`}
      header="Reorder Photos"
      modalFooter={
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            sendPhotosToServer()
          }}
          disabled={loading}
        >
          {loading ? "Loading..." : "Save changes"}
        </button>
      }
    >
      {loadingPhotos ? <p>Loading...</p> : <SortPhotos photos={photos} setUpdatedPhotos={setUpdatedPhotos} car={hit} />}
    </Modal>
  )
}

export default PhotosPopup
