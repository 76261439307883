import { useContext } from 'react'
import { TestDriveContext } from '../../../contexts'

interface DataRowProps {
  label: string
  value: string
}

interface DataBlockProps {
  title: string
  details: DataRowProps[]
}

const DataRow = ({ label, value }: DataRowProps) => (
  <div className="row my-3 pl-2">
    <div className="col-md-4">
      <b>{label}</b>
    </div>
    <div className="col-md-8 text-secondary">{value}</div>
  </div>
)

const DataBlock = ({ title, details }: DataBlockProps) => (
  <div className="col-sm-12 col-md-6 md-my-4">
    <div className="card mb-4">
      <div className="card-header">{title}</div>
      <div className="card-body">
        {details.map((detail, index) => (
          <DataRow key={index} label={detail.label} value={detail.value} />
        ))}
      </div>
    </div>
  </div>
)

export const CustomerVehicleData = () => {
  const { testDrive }: any = useContext(TestDriveContext)
  if (!testDrive) return null
  const { car, contact } = testDrive
  const customerDetails = [
    { label: 'First Name', value: contact.first_name },
    { label: 'Last Name', value: contact.last_name },
    { label: 'Email', value: contact.email },
    { label: 'Phone', value: contact.phone_number },
  ]

  const vehicleDetails = [
    { label: 'Make', value: car.make },
    { label: 'Model', value: car.model },
    { label: 'Year', value: car.year },
    { label: 'Stock Number', value: car.stocknum },
  ]

  return (
    <div>
      <div className="row">
        <DataBlock title="Customer Data" details={customerDetails} />
        <DataBlock title="Vehicle Data" details={vehicleDetails} />
      </div>
    </div>
  )
}
