import {
  createBrowserRouter,
  RouterProvider,
  ScrollRestoration,
  Route,
  createRoutesFromElements
} from "react-router-dom"
// import KeepAlive, { AliveScope } from 'react-activation'
import * as ReactDOM from "react-dom/client"
import { RootContext, CurrentUserProvider, DealershipProvider, WebsiteProvider, TestDriveProvider } from './contexts'
import AdminBilling from './admin/Billing'
import AdminPushNotifications from './admin/PushNotifications'
import AdminVehicleDetailLookups from "./admin/VehicleDetailLookups"
import AppointmentsCalendar from './appointments/Calendar'
import CarsAutograbIndex from './cars/AutograbIndex'
import CarsEditRedbookDetails from './cars/EditRedbookDetails'
import CarShow from './cars/show'
import CarsReports from './cars/Reports'
import CarsSearch from './cars/Search'
import CarsSmartPublisher from './cars/SmartPublisher'
import ContactsSearch from './contacts/searchContacts'
import DealershipUsersUnavailableIntervals from "./unavailableIntervals/dealershipUsersUnavailableIntervals"
import LeadClusterShow from './leadCluster/Show'
import LeadClustersSearch from './leadCluster/Search'
import LeadSettings from './settings/leadSettings'
import LeadShow from './leads/Show'
import LeadsSearch from './leads/Search'
import Plugins from './plugins'
import SearchTestDrives from './testDrives/SearchTestDrives'
import StockImageSetEditForm from './StockImageSets/EditForm'
import StockImageSetNewForm from './StockImageSets/NewForm'
import StockImageSetsSearch from './StockImageSets/Search'
import TestDriveForm from './testDrives/TestDriveForm'
import UsersSearch from './users/Search'
import WebsitesMenuEditor from './websites/MenuEditor'
import WebsitesSearchPages from './websites/SearchPages'
import IndexPage from "./Home/Index"

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='/' element={<IndexPage />} />
      <Route path='/admin/billing' element={<AdminBilling />} />
      <Route path='/admin/vehicle_detail_lookups' element={<AdminVehicleDetailLookups />} />
      <Route path='/dealerships/:dealershipSlug/appointments' element={<AppointmentsCalendar />} />
      <Route path='/dealerships/:dealershipSlug/cars/autograb' element={<CarsAutograbIndex />} />
      <Route path='/dealerships/:dealershipSlug/cars/reports' element={<CarsReports />} />
      <Route path='/dealerships/:dealershipSlug/cars/smart_publisher' element={<CarsSmartPublisher />} />
      <Route path='/dealerships/:dealershipSlug/contacts' element={<ContactsSearch />} />
      <Route path='/dealerships/:dealershipSlug/dealership_users_unavailable_intervals' element={<DealershipUsersUnavailableIntervals />} />
      <Route path='/dealerships/:dealershipSlug/lead_settings' element={<LeadSettings />} />
      <Route path='/dealerships/:dealershipSlug/plugins' element={<Plugins />} />
      <Route path="/dealerships/:dealershipSlug/lead_clusters/pipelines/:pipelineId" element={<LeadClustersSearch />} />
      <Route path="/dealerships/:dealershipSlug/leads/kanban" element={<LeadClustersSearch defaultLayout="kanban" />} />
      <Route path="/dealerships/:dealershipSlug/leads/kanban/pipelines/:pipelineId" element={<LeadClustersSearch defaultLayout="kanban" />} />
      <Route path='/dealerships/:dealershipSlug/test_drives' element={<SearchTestDrives />} />
      <Route path='/dealerships/:dealershipSlug/test_drives/:testDriveId/edit' element={<TestDriveProvider><TestDriveForm /></TestDriveProvider>} />
      <Route path='/dealerships/:dealershipSlug/test_drives/new' element={<TestDriveProvider><TestDriveForm /></TestDriveProvider>} />
      <Route path='/dealerships/:dealershipSlug/users' element={<UsersSearch />} />
      <Route path='/stock_image_sets' element={<StockImageSetsSearch />} />
      <Route path='/stock_image_sets/:id/edit' element={<StockImageSetEditForm />} />
      <Route path='/stock_image_sets/new' element={<StockImageSetNewForm />} />
      <Route path='/users' element={<UsersSearch />} />
      <Route path='/websites/:websiteSlug/menus' element={<WebsitesMenuEditor />} />
      <Route path='/websites/:websiteSlug/search_pages' element={<WebsitesSearchPages />} />
      <Route path="/admin/all_cars" element={<CarsSearch />} />
      <Route path="/admin/push_notifications" element={<AdminPushNotifications />} />
      <Route path="/cars/:carId" element={<CarShow />} />
      <Route path="/dealerships/:dealershipSlug/cars" element={<CarsSearch />} />
      <Route path="/dealerships/:dealershipSlug/cars/:carId/edit_redbook_details" element={<CarsEditRedbookDetails />} />
      <Route path="/dealerships/:dealershipSlug/lead_clusters" element={<LeadClustersSearch />} />
      <Route path="/dealerships/:dealershipSlug/lead_clusters/:leadClusterId" element={<LeadClusterShow />} />
      <Route path="/dealerships/:dealershipSlug/leads" element={<LeadsSearch />} />{/* DEPRECATED */}
      <Route path="/dealerships/:dealershipSlug/leads/:leadId" element={<LeadShow />} />{/* DEPRECATED */}
      <Route path="/websites/:websiteSlug/leads" element={<LeadClustersSearch />} />
    </>
  )
)

ReactDOM.createRoot(document.getElementById("app_root")).render(
  <RootContext.Provider value={{}}>
    <CurrentUserProvider>
      <DealershipProvider>
        <WebsiteProvider>
          <RouterProvider router={router}>
            <ScrollRestoration />
          </RouterProvider>
        </WebsiteProvider>
      </DealershipProvider>
    </CurrentUserProvider>
  </RootContext.Provider>
)
