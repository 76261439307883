// MANAGED BY App.js
import { useState, useEffect, useRef, useContext, useMemo } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { Toast } from 'primereact/toast'

import LeadEventsContainer from '../leads/LeadEvents'
import Appointments from '../appointments/Appointments'
import LeadPayment from '../leads/LeadPayment'
import SmsConversation from '../leads/SmsConversation'
import LeadClusterSidebar from './Sidebar'
import { Skeleton } from 'primereact/skeleton'
import { DealershipContext, LeadClusterContext } from '../contexts'
import Cars from './Cars'
import LeadContainer from './LeadContainer'
import { Message } from 'primereact/message'
import ProgressTracker from './ProgressTracker'
import LeadClusterStateChanges from './LeadClusterStateChanges'
import { useFetchDealership } from '../dataHooks'
import { Panel } from 'primereact/panel'


const TabOne = () => {
  return (
    <>
      {/* {lead?.creditScoreRequests.map((creditScoreRequest) => <CreditScoreRequest creditScoreRequest={creditScoreRequest} key={creditScoreRequest.id} />)} */}
      <Appointments />
      <LeadEventsContainer />
    </>
  )
}

const LeadMain = () => {
  const navigate = useNavigate()

  const handleBack = () => {
    // Go back to the previous page
    navigate(-1)
  }
  const { leadCluster } = useContext(LeadClusterContext)

  const firstLead = leadCluster.leads[0]

  return (
    <>
      <div className="px-3 pt-1">
        <button className="btn btn-outline-secondary btn-block-md-down mr-auto mb-2" onClick={handleBack}>
          <i className="fas fa-arrow-left mr-2" /> Back
        </button>
      </div>
      {leadCluster.requires_approval && (
        <div className="mb-2 px-3 w-100">
          <Message className="w-100" severity="warn" text={`Lead requires manager approval to move to status "${leadCluster.lead_state}"`} />
        </div>
      )}
      <ProgressTracker />
      <Tabs>
        <div className="px-3 py-1">
          <div className="rounded border overflow-hidden lead-tabs">
            <TabList>
              <div className="d-flex">
                <Tab>Lead Events</Tab>
                <Tab>
                  <div id="sms-conversation-button">
                    SMS Conversation ({leadCluster.texts?.length}) <div className="badge badge-danger">BETA</div>
                  </div>
                </Tab>
              </div>
            </TabList>
          </div>
        </div>
        <TabPanel>
          <div className="px-3">
            {leadCluster.payments.map(payment => (
              <LeadPayment payment={payment} key={payment.id} />
            ))}
            <Cars />
            {leadCluster.state_changes.length > 0 && <LeadClusterStateChanges stateChanges={leadCluster.state_changes} />}
            {firstLead?.visited_pages?.length > 0 && (
              <p className="small mb-1 mt-2 text-secondary text-center">
                {leadCluster?.contact.first_name} visited {firstLead.visited_pages.length} pages before submitting first lead
              </p>
            )}
          </div>
          <div className="px-3 py-2">
            <Panel header={`Leads (${leadCluster.leads.length})`} toggleable>
              {leadCluster.leads.map((lead, i) => (
                <LeadContainer lead={lead} key={lead.id} position={i} />
              ))}
            </Panel>
          </div>
          <TabOne></TabOne>
        </TabPanel>
        <TabPanel>
          <SmsConversation />
        </TabPanel>
      </Tabs>
    </>
  )
}

export default function Show() {
  const { leadClusterId } = useParams()
  const { state } = useLocation()
  const [status, setStatus] = useState('loading')
  const rootElement = document.getElementById('app_root')
  const testDrivesEnabled = rootElement?.getAttribute('test_drives_enabled') === 'true'
  const features = { 'test_drives': testDrivesEnabled }
  const notification = useRef(null)
  let [leadCluster, setLeadCluster] = useState(state)
  const { dealership } = useContext(DealershipContext)
  useFetchDealership()

  useEffect(() => {
    if (status !== 'completed' && dealership?.slug) {
      fetch(`${window.location.origin}/dealerships/${dealership.slug}/lead_clusters/${leadClusterId}.json`)
        .then((response) => {
          if (!response.ok) {
            if (response.status === 404) {
              throw new Error('Not Found')
            } else {
              throw new Error('An error occurred')
            }
          }
          return response.json()
        })
        .then((data) => {
          setLeadCluster(data)
          setStatus('completed')
        })
        .catch((error) => {
          if (error.message === 'Not Found') {
            setStatus('not_found')
          } else {
            setStatus('error')
          }
        })
    }
  }, [dealership?.slug])

  useEffect(() => {
    document.body.scrollTo(0, 0)
  })

  const contextValue = useMemo(() => ({ leadCluster, features, setLeadCluster, notification }), [leadCluster, features, setLeadCluster, notification])

  if (status === 'not_found') {
    return (
      <div className="text-center mt-5 text-danger">
        <h2>Lead Cluster Not Found</h2>
      </div>
    )
  }

  return (
    <div className="w-100">
      <LeadClusterContext.Provider value={{ leadCluster, features, setLeadCluster, notification }}>
        <div className="row no-gutters h-100">
          <div className="col-md-9">
            <div className="py-3">
              {status === 'completed' ? <LeadMain /> : (
                <div className="px-3 pt-1 py-3">
                  <Skeleton
                    width="10rem"
                    className="mb-2 w-100"
                    height="10rem"
                  />
                  <Skeleton
                    width="10rem"
                    className="mb-2 w-100"
                    height="10rem"
                  />
                  <Skeleton
                    width="10rem"
                    className="mb-2 w-100"
                    height="10rem"
                  />
                </div>
              )}
            </div >
          </div>
          <Toast ref={notification} />
          <LeadClusterSidebar
            status={status}
            notification={notification}
          />
        </div>
      </LeadClusterContext.Provider>
    </div>
  )
}