import { useContext } from 'react'
import { humanize } from '../entries/utils'
import { LeadContext } from '../contexts'
import TestDriveButton from '../testDrives/components/TestDriveButton';

const CarBox = () => {
  const { lead, features } = useContext(LeadContext)
  const { car } = lead

  const carStatusClass = car.status === 'sold' ? 'text-danger' : 'text-success';
  const carKm = car.km ? `${Number(car.km).toLocaleString()} kms` : '0 kms';
  const carPrice = car.price ? car.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '$0.00';

  return (
    <div className="border bg-white rounded p-3 mb-1">
      <div className="d-flex align-items-center">
        {car.primary_image_url && (
          <img src={car.primary_image_url} height="75" className="mr-3" alt={car.name} />
        )}
        <div className="mr-auto">
          <h6 className="mb-0">{car.name}</h6>
          <div className={carStatusClass}>{car.status ? humanize(car.status) : ''}</div>
          <div className="text-secondary">
            Stock number: {car.stocknum}
            <span> - </span>
            Rego: {car.regplate}
            <span> - </span>
            {carKm}
            <span> - </span>
            {carPrice}
          </div>
        </div>
        <div>
          <a href={`/cars/${car.slug}`} className="btn btn-outline-primary">
            <i className="fa fa-magnifying-glass"></i> View Car
          </a>
        </div>
        {features?.test_drives && (
          <div className="ml-2"><TestDriveButton /></div>
        )}
      </div>
    </div>
  )
}

export default CarBox;