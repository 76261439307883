import { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Input, Select, Errors } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'

const Form = ({
  categories,
  loadPipelines
}) => {
  let [visible, setVisible] = useState(false)
  let [name, setName] = useState('')
  let [selectedCategories, setSelectedCategories] = useState([])
  let [loading, setLoading] = useState(false)
  let [errors, setErrors] = useState([])

  const save = () => {
    let data = {
      pipeline: {
        name: name,
        categories: selectedCategories
      }
    }

    setLoading(true)
    fetch(`/dealerships/${window.dealership.id}/pipelines`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: standardHeaders,
    })
      .then((response) => {
        if (response.status === 422) {
          return response.json().then((data) => {
            setLoading(false);
            setErrors(data);
            return Promise.reject(data);
          });
        }
        return response.json(); // Handle other statuses normally
      })
      .then(() => {
        loadPipelines()
        setLoading(false)
        setVisible(false)
      })
  }

  return (
    <div>
      <button
        className="btn btn-outline-primary"
        onClick={() => setVisible(true)}
        id="new-pipeline-button"
      >
        <i className="fa fa-plus mr-2"></i>
        Pipeline
      </button>
      <Dialog
        header="New Pipeline"
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
        dismissableMask={true}
      >
        <Input
          label="Name"
          placeholder="Name eg. Service Pipeline"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Select
          label="Lead Categories"
          options={categories.map(category => ({ label: category, value: category }))}
          isMulti={true}
          className="categories-select"
          onChange={(e) => setSelectedCategories(e.map(c => c.value))}
          menuPortalTarget={document.body}
        />
        <Errors errors={errors} />
        <button
          className="btn btn-primary"
          onClick={save}
          disabled={loading}
          id="save-pipeline-button"
        >
          {loading ? <i className="fa fa-spin fa-spinner mr-1" /> : <i className="fa fa-save mr-1" />} Save
        </button>
      </Dialog>
    </div>
  )
}

export default Form