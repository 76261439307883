import { useState, useEffect, useContext } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { SplitButton } from 'primereact/splitbutton'
import { FilterMatchMode, PrimeReactProvider, FilterService } from 'primereact/api'
import { Dropdown } from 'primereact/dropdown'
import { Tag } from 'primereact/tag'

import DataTableSearch from '../entries/DataTableSearch'
import { truncateString, time_ago } from '../entries/utils'
import ItemStatus from './ItemStatus'
import { TestDriveService } from './testDriveService'
import { DealershipContext, TestDriveProvider } from '../contexts'
import { useFetchDealership, useFetchTestDrive } from '../dataHooks'

const Action = (testDrive) => {
  let url = `/dealerships/${testDrive.dealership_id}/test_drives/${testDrive.id}`

  useFetchTestDrive()

  const items = [
    {
      label: 'Audit Trail',
      icon: 'fa fa-history',
      command: () => {
        window.location.href = `${url}/audit_trail`
      }
    },
    {
      label: 'Destroy',
      icon: 'fa fa-trash',
      command: () => {
        fetch(url, {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          }
        }).then(() => {
          window.location.reload()
        })
      }
    }
  ]

  return (
    <div style={{ position: 'relative' }}>
      <SplitButton
        label="Edit"
        buttonProps={{ id: 'edit-button' }}
        onClick={() => { window.location.href = `${url}/edit` }}
        model={items}
        menuButtonProps={{ id: 'action-button' }}
        outlined
        rounded
      />
    </div >
  )
}

const customFilter = (value, filters) => {
  if (value === '' || (value !== '' && filters === '')) {
    return true
  }
  return value === filters
}

FilterService.register('custom_location', customFilter)

const LastEditBy = (testDrive) => {
  return (
    <div>
      <div>{testDrive.last_edit_by.name}</div>
      <div className="small text-secondary">{time_ago(testDrive.updated_at)}</div>
    </div>
  )
}

export const Title = (testDrive) => {
  let url = `/dealerships/${testDrive.dealership_id}/test_drives/${testDrive.id}/edit`
  return (
    <>
      <a href={url}>
        {truncateString(testDrive.title, 50)}
      </a>
      <div className="small text-secondary">
        Created: {time_ago(testDrive.created_at)}
      </div>
      {testDrive?.lead?.id && (
        <div className="small text-secondary">
          <a href={`/dealerships/${testDrive.dealership_id}/leads/${testDrive.lead.id}`}><i className="fa fa-bolt"></i> Lead</a>
        </div>
      )}
    </>
  )
}

export const Contact = (testDrive) => {
  let url = `/dealerships/${testDrive.dealership_id}/contacts/${testDrive.contact_id}`
  return (
    <div>
      <a href={url}>
        {testDrive.contact_name}
      </a>
      <div className="small text-secondary">{testDrive.contact_phone_number}</div>
      <div className="small text-secondary">{testDrive.contact_email}</div>
    </div>
  )
}

export const Status = (testDrive) => {
  return (
    <ItemStatus status={testDrive.status} />
  )
}

export const Location = (testDrive) => {
  return (
    testDrive.location || 'All'
  )
}

const App = () => {
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: 'contains' },
    title: { value: null, matchMode: 'contains' },
    locations: { value: '', matchMode: FilterMatchMode.CUSTOM },
  })
  const { dealership } = useContext(DealershipContext)
  const locations = dealership?.locations
  const dealershipId = dealership?.id

  useFetchDealership()

  const getSeverity = (status) => {
    switch (status) {
      case 'Ready':
        return 'primary'

      case 'Completed':
        return 'success'

      case 'In Progress':
        return 'warning'
    }
  }

  const [totalRecords, setTotalRecords] = useState(0)
  const [testDrives, setTestDrives] = useState(null)

  const [statuses] = useState(['Completed', 'Ready', 'In Progress'])

  const statusItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverity(option)} />
  }

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Select Status" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
    )
  }
  const optionsMap = {
    'Location': locations?.map(location => ({ label: location, value: location }))
  }

  const locationRowFilterTemplate = (options) => rowFilterTemplate(options, 'Location')

  const rowFilterTemplate = (options, name, width = '10rem') => {
    const selectedOptions = optionsMap[name]
    return (
      <Dropdown value={options.value} options={selectedOptions} onChange={(e) => options.filterApplyCallback(e.value)} placeholder={`Select ${name}`} className="p-column-filter" style={{ maxWidth: width }} />
    )
  }

  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 50,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      global: { value: '', matchMode: 'contains' }, // FilterMatchMode.CONTAINS is not used because lazyState was not updating
      'status': { value: null, matchMode: 'equals' },
      'title': { value: null, matchMode: 'contains' },
      'location': { value: null, matchMode: 'contains' },
      'contact_name': { value: null, matchMode: 'contains' },
      'contact_phone_number': { value: null, matchMode: 'contains' },
      'contact_email': { value: null, matchMode: 'contains' },
      'last_edit_by': { value: null, matchMode: 'contains' }
    }
  })

  useEffect(() => {
    loadLazyData()
  }, [lazyState, dealership])

  const loadLazyData = () => {
    if (lazyState.page === undefined) {
      return
    }
    let page_number = 0
    if (lazyState.page === 0 || lazyState.first === 0) {
      page_number = 1
    } else {
      page_number = (lazyState.page + 1)
    }
    let params = { page: page_number, rows: lazyState.rows }
    let csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content")
    let dealershipId = dealership?.id

    if (dealershipId) {
      // obtain additional filter params (car_id, contact_id, lead_id) from current URL
      let urlParams = new URLSearchParams(window.location.search)
      try {
        TestDriveService.getTestDrives({ params, csrf, dealershipId, urlParams }).then((data) => {
          setTotalRecords(data.data.total_records)
          setTestDrives(data.data.test_drives)
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  const onFilter = (event) => {
    event['first'] = 0
    setlazyState(event)
  }

  const header = DataTableSearch({ filters: lazyState.filters, setFilters: setFilters })

  return (
    <PrimeReactProvider>
      <TestDriveProvider>
        <div className="pt-3 container-fluid">
          <div className="mb-3 d-flex align-items-center">
            <h4 className="m-0">Test Drives</h4>
            <div className="ml-auto">
              <a href={`/dealerships/${dealershipId}/test_drives/new`} className="btn btn-outline-success">
                <i className="fa fa-plus mr-1"></i>
                New Test Drive
              </a>
            </div>
          </div>
          <div className="box">
            <DataTable
              value={testDrives}
              tableStyle={{ minWidth: '50rem' }}
              paginator rows={lazyState.rows}
              removableSort
              sortMode="multiple"
              scrollable
              globalFilterFields={['title', 'contact_name', 'status', 'location', 'last_edit_by', 'contact_phone_number', 'contact_email']}
              style={{ overflow: 'visible' }}
              wrapper={{ style: { overflow: 'visible' } }}
              header={header}
              first={lazyState.first}
              totalRecords={totalRecords}
              filters={lazyState.filters}
              filterDisplay='row'

            >
              <Column sortable body={Title} field="title" header="Title"></Column>
              <Column sortable body={Contact} field="contact_name" header="Contact"></Column>
              <Column field="status" header="Status" sortable showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} body={Status} filter filterElement={statusRowFilterTemplate} />
              <Column sortable body={Location} field="location" header="Location" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} filter filterElement={locationRowFilterTemplate}></Column>
              <Column sortable body={LastEditBy} field="last_edit_by" header="Last Edited By"></Column>
              <Column body={Action} header="Actions"></Column>
            </DataTable>
          </div>
        </div>
      </TestDriveProvider>
    </PrimeReactProvider >
  )
}

export default App