import { useState, useEffect, useContext } from 'react'
import { Dialog } from 'primereact/dialog'
import { useParams } from "react-router-dom"
import { DealershipContext } from '../../contexts'
import { standardHeaders } from '../../entries/utils'
import { Errors, Select, Input } from '../../entries/FormElements'
import moment from 'moment'
import { useFetchDealership } from '../../dataHooks'

const CustomReport = ({ customReport, fetchCustomReports, fetchData, customReportsData, data }) => {
  let [deleting, setDeleting] = useState(false)
  let [loading, setLoading] = useState(false)
  let { dealershipSlug } = useParams()

  const destroy = (customReport) => {
    setDeleting(true)
    fetch(`/dealerships/${dealershipSlug}/custom_reports/${customReport.id}.json`, {
      method: 'DELETE',
      headers: standardHeaders
    })
      .then(response => {
        return response.json()
      })
      .then((data) => {
        fetchCustomReports()
        setDeleting(false)
      }).catch(error => {
        console.error('Error:', error)
      })
  }

  const generate = (customReport) => {
    setLoading(true)
    fetch(`/dealerships/${dealershipSlug}/custom_reports/${customReport.id}/generate.json`, {
      method: 'POST',
      headers: standardHeaders
    })
      .then(response => {
        return response.json()
      })
      .then((data) => {
        fetchData()
        setLoading(false)
      }).catch(error => {
        console.error('Error:', error)
      })
  }

  return (
    <tr>
      <td>
        <div>
          {customReport.name}
        </div>
        <span className="small text-secondary">
          Created {moment(customReport.created_at).fromNow()}
        </span>
      </td>
      <td>
        {customReport.car_types.join(', ')}
      </td>
      <td>
        {customReport.manufacturer_names}
      </td>
      <td>
        {customReport.location_names.map(location => (
          <div className="badge badge-secondary mr-1" key={location}>{location}</div>
        ))}
      </td>
      <td>
        <div className="badge badge-secondary">
          {customReport.fields.length}
        </div>
      </td>
      <td>
        <button
          onClick={() => generate(customReport)}
          className={"btn btn-outline-primary btn-sm mr-2" + (loading ? ' disabled' : '')}
          disabled={loading}
        >
          {loading ? (
            <span>
              <i className="fa fa-spinner fa-spin mr-1"></i>
              Loading...
            </span>
          ) : (
            <>Generate</>
          )}
        </button>
        <EditCustomReport
          customReport={customReport}
          customReportsData={customReportsData}
          fetchCustomReports={fetchCustomReports}
          data={data}
        />
        <button
          onClick={() => destroy(customReport)}
          className={"btn btn-outline-danger btn-sm" + (deleting ? ' disabled' : '')}
          disabled={deleting}
        >
          {deleting ? (
            <span>
              <i className="fa fa-spinner fa-spin mr-1"></i>
              Loading...
            </span>
          ) : (
            <>Destroy</>
          )}
        </button>
      </td>
    </tr>
  )
}

const EditCustomReport = ({ customReport, customReportsData, data, fetchCustomReports }) => {
  return (
    <CustomReportForm
      customReportsData={customReportsData}
      data={data}
      customReport={customReport}
      fetchCustomReports={fetchCustomReports}
    >
      <i className="fa fa-edit mr-1"></i>
      Edit
    </CustomReportForm>
  )
}

export const CustomReports = ({ data, fetchData }) => {
  let { dealershipSlug } = useParams()
  let [customReportsData, setCustomReportsData] = useState(null)

  const fetchCustomReports = () => {
    fetch(`/dealerships/${dealershipSlug}/custom_reports.json`)
      .then(res => res.json())
      .then(res => setCustomReportsData(res))
  }

  useEffect(() => {
    fetchCustomReports()
  }, [])

  return (
    <div className="box mt-3">
      <div className="d-flex p-3">
        <div className="mr-auto">
          <h4>My Custom Reports</h4>
        </div>
        <div className="float-left">
          {data && customReportsData && (
            <CustomReportForm
              data={data}
              fetchCustomReports={fetchCustomReports}
              fetchData={fetchData}
              customReportsData={customReportsData}
            >
              <i className="fa fa-plus mr-1"></i>
              New Custom Report
            </CustomReportForm>
          )}
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Car Types</th>
            <th>Makes</th>
            <th>Locations</th>
            <th>Fields</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {customReportsData && customReportsData.custom_reports?.map(customReport => (
            <CustomReport
              key={customReport.id}
              customReport={customReport}
              fetchData={fetchData}
              fetchCustomReports={fetchCustomReports}
              customReportsData={customReportsData}
              data={data}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export const CustomReportForm = ({ data, fetchCustomReports, customReportsData, customReport, children }) => {
  let { dealershipSlug } = useParams()
  const { dealership } = useContext(DealershipContext)

  useFetchDealership()
  let [visible, setVisible] = useState(false)

  // Form Fields
  let [name, setName] = useState(customReport?.name || '')
  let [carType, setCarType] = useState(customReport?.car_types || [])
  let [locations, setLocations] = useState(customReport?.location_ids || [])
  let [manufacturers, setManufacturers] = useState(customReport?.manufacturer_ids || [])
  let [fields, setFields] = useState(customReport?.fields || customReportsData.default_fields)

  let [loading, setLoading] = useState(false)
  let [statuses, setStatuses] = useState(['in_stock'])
  let [errors, setErrors] = useState(false)

  const createCustomReport = () => {

    let url = `/dealerships/${dealershipSlug}/custom_reports.json`
    let method = 'POST'
    if (customReport?.id) {
      url = `/dealerships/${dealershipSlug}/custom_reports/${customReport.id}`
      method = 'PUT'
    }

    setLoading(true)
    fetch(url, {
      method: method,
      headers: standardHeaders,
      body: JSON.stringify({
        custom_report: {
          name: name,
          car_types: carType,
          manufacturer_ids: manufacturers,
          location_ids: locations,
          dealership_id: dealership.id,
          fields: fields,
          statuses: statuses
        }
      })
    })
      .then(response => {
        return response.json()
      })
      .then((data) => {
        if (data.errors) {
          setLoading(false)
          setErrors(data.errors)
        } else {
          setLoading(false)
          setVisible(false)
          fetchCustomReports()
        }
      }).catch(error => {
        setLoading(false)
        console.error('Error:', error)
      })
  }

  let manufacturerOptions = data.manufacturers.map((manufacturer) => ({ label: `${manufacturer.manufacturer_name} (${manufacturer.count})`, value: manufacturer.manufacturer_id }))
  let locationOptions = data.locations.map((location) => ({ label: `${location.location_name} (${location.count})`, value: location.location_id }))

  return (
    <>
      <button className="btn btn-outline-secondary btn-sm mr-1" onClick={() => setVisible(true)}>
        {children}
      </button>
      <Dialog
        header={`${customReport?.id ? 'Edit Report' : 'New Custom Report'}`}
        visible={visible}
        style={{ width: '50vw' }}
        breakpoints={{ '960px': '75vw', '641px': '100vw' }}
        modal={true}
        onHide={() => setVisible(false)}
        dismissableMask={true}
      >
        <div className="mb-3">
          <Input
            label="Name"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <Select
            label="Statuses"
            placeholder="Select in_stock, sold..."
            options={Object.keys(data.statuses).map((status) => ({ label: `${status} (${data.statuses[status]})`, value: status }))}
            onChange={(selectedOption) => setStatuses(selectedOption.map(o => o.value))}
            menuPortalTarget={document.body}
            defaultValue={statuses.map((status) => ({ label: status, value: status }))}
            isMulti={true}
          />
        </div>
        <div className="form-group">
          <Select
            label="Car Type"
            placeholder="Select New, Used, Demo..."
            options={Object.keys(data.car_types).map((carType) => ({ label: `${carType} (${data.car_types[carType]})`, value: carType }))}
            onChange={(selectedOption) => setCarType(selectedOption.map(o => o.value))}
            menuPortalTarget={document.body}
            defaultValue={carType.map((carType) => ({ label: carType, value: carType }))}
            isMulti={true}
          />
        </div>
        <div className="form-group">
          <Select
            label="Make"
            placeholder="Select Make..."
            options={manufacturerOptions}
            onChange={(selectedOption) => setManufacturers(selectedOption.map(o => o.value))}
            menuPortalTarget={document.body}
            defaultValue={manufacturerOptions.filter(m => manufacturers.includes(m.value.toString()))}
            isMulti={true}
          />
        </div>
        <div className="form-group">
          <Select
            label="Location"
            placeholder="Select Location..."
            options={locationOptions}
            onChange={(selectedOption) => setLocations(selectedOption.map(o => o.value))}
            defaultValue={locationOptions.filter(l => locations.includes(l.value.toString()))}
            isMulti={true}
            menuPortalTarget={document.body}
          />
        </div>
        <div className="form-group">
          <Select
            label="Fields"
            placeholder="Select Fields"
            options={customReportsData.all_fields.map((field) => ({ label: field, value: field }))}
            onChange={(selectedOption) => setFields(selectedOption.map(o => o.value))}
            isMulti={true}
            defaultValue={fields.map((field) => ({ label: field, value: field }))}
            menuPortalTarget={document.body}
          />
        </div>
        <div
          className={"btn btn-outline-success btn-block mb-3" + (loading ? ' disabled' : '')}
          onClick={createCustomReport}
          disabled={loading}
        >
          {loading ? (
            <span>
              <i className="fa fa-spinner fa-spin mr-1"></i>
              Loading...
            </span>
          ) : (
            <>
              {customReport?.id ? 'Update' : 'Add'} Report
            </>
          )}
        </div>
        {errors && (
          <Errors errors={errors} />
        )}
      </Dialog>
    </>
  )
}