
import { useState, useEffect, useContext } from 'react'
import moment from 'moment'
import { humanize } from '../entries/utils'
import { Tooltip } from 'react-tooltip'
import LeadEventForm from './LeadEventForm'
import { ownerContext } from './utils'
import { standardHeaders } from '../entries/utils'

const LeadEvent = ({ event, setLeadEvents }) => {
  let { owner } = ownerContext()

  let [loading, setLoading] = useState(false)

  const removeEvent = () => {
    if (!window.confirm('Delete the item?')) {
      return
    }

    setLoading(true)

    const url = (owner.model_name === 'LeadCluster') ? `/lead_clusters/${owner.id}/lead_events/${event.id}` : `/leads/${owner.id}/lead_events/${event.id}`

    fetch(url, {
      method: 'DELETE',
      headers: standardHeaders,
    })
      .then(response => response.json())
      .then(data => {
        if (data.errors) {
          console.log(data)
          throw new Error('An error occured')
        }

        setLoading(false)
        setLeadEvents(owner.leadEvents.filter(leadEvent => leadEvent.id !== event.id))
      })
  }

  return (
    <li className={`pb-1 ${event.event_type}`}>
      <div className="float-right text-right mr-3">
        <span href="#" className="float-right text-secondary" id={`event-${event.id}`}>{moment(event.created_at).fromNow()}</span>
        <div className="small text-secondary">
          <a onClick={removeEvent} style={{ cursor: 'pointer' }}>
            {loading ? "Loading..." : "Remove"}
          </a>
        </div>
      </div>
      <Tooltip
        anchorSelect={`#event-${event.id}`}
        place="bottom"
        style={{ zIndex: 10000 }}
      >
        {moment(event.created_at).format("DD/MM/YYYY HH:mm")}
      </Tooltip>
      <h6>
        {humanize(event.event_type)}
      </h6>
      <div>{event.description}</div>
      <span className="small text-secondary">
        Event created by: {event.user?.name || event.external_service}
      </span>
    </li>
  )
}

const LeadEvents = ({ leadEvents, setLeadEvents, loading }) => {
  return (
    <div className="px-3 py-1">
      <div className="box">
        <div className="px-3 pt-3">
          <h5 className="text-secondary">Lead Events</h5>
        </div>
        {loading && (
          <div className="p-3">
            <i className="fas fa-spinner fa-spin mr-2"></i>
            Loading...
          </div>
        )}
        <ul className="timeline">
          {leadEvents && leadEvents.map(leadEvent => (
            <LeadEvent
              event={leadEvent}
              setLeadEvents={setLeadEvents}
              key={`event-${leadEvent.id}`}
            />
          ))}
        </ul>
      </div>
    </div>
  )
}

const LeadEventsContainer = () => {
  const { owner, setOwner } = ownerContext();
  const { leadEvents: defaultLeadEvents } = owner
  let [leadEvents, setLeadEvents] = useState(defaultLeadEvents || false)
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    if (defaultLeadEvents) {
      return
    }

    const url = (owner.model_name === 'LeadCluster') ? `/dealerships/${window.dealer_slug}/lead_clusters/${owner.id}` : `/dealerships/${window.dealer_slug}/leads/${owner.id}`

    setLoading(true)
    fetch(url)
      .then(response => response.json())
      .then(data => {
        setLeadEvents(data.leadEvents)
        setOwner({ ...owner, event_counts: data.event_counts })
        setLoading(false)
      })
  }, [])

  return (
    <>
      <LeadEvents leadEvents={leadEvents} setLeadEvents={setLeadEvents} loading={loading} />
      <div className="px-3 py-1">
        <div className="box">
          <LeadEventForm setLeadEvents={setLeadEvents} />
        </div>
      </div>
    </>
  )
}

export default LeadEventsContainer