import React, { useEffect } from 'react';

const SlideIn = ({ isOpen, children, setIsOpen, afterOpen, afterClose }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('disable-scroll')
    } else {
      document.body.classList.remove('disable-scroll')
    }
    setTimeout(() => {
      if (isOpen) {
        afterOpen && afterOpen()
      } else {
        afterClose && afterClose()
      }
    }, 750);
  }, [isOpen])

  return (
    <div className={`slideInWrapper ${isOpen ? 'open' : ''}`}>
      <div className={`slideInInner p-3 ${isOpen ? 'open' : ''}`}>
        {children}
      </div>
      <div className="slideInBackdrop" onClick={e => setIsOpen(false)}></div>
    </div>
  )
}

export default SlideIn