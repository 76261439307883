import { useContext } from 'react'
import { LeadClusterContext } from '../contexts'
import { humanize } from '../entries/utils'

const Item = ({ leadCluster, status, active, currentColour }) => {

  let currentState = leadCluster.lead_state == status.label

  return (
    <div
      className={"col py-2 status-item position-relative d-flex align-items-center" + (active ? ` bg-${currentColour}` : ' border-right')}
    >
      {currentState && (
        <div className={`angle-right bg-${currentColour}`} />
      )}
      <div className={"text-center"}>
        <small className={active ? "font-weight-bold text-white" : ''}>
          {humanize(status.label)}
        </small>
      </div>
    </div>
  )
}

const ProgressTracker = () => {
  const { leadCluster } = useContext(LeadClusterContext)

  const indexOfCurrentStatus = leadCluster.lead_status_options.findIndex(status => status.label === leadCluster.lead_state)

  let currentColour = leadCluster.lead_status_options.find((status) => status.label === leadCluster.lead_state)?.class

  return (
    <div className="px-3 mx-3 mb-1 d-none d-xl-block">
      <div>
        <div className="row border rounded overflow-hidden">
          {leadCluster.lead_status_options.map((status, index) => (
            <Item
              leadCluster={leadCluster}
              status={status}
              key={`${index} -status - option`}
              active={index <= indexOfCurrentStatus}
              currentColour={currentColour}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProgressTracker