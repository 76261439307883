import { useState, useEffect, useContext } from 'react'
import { SelectFetch } from 'react-select-fetch'
import * as Routes from '../../../../routes'
import { DealershipContext } from '../../../contexts'

export const NewTestDrive = ({ onCreateTestDrive }) => {
  const [selectedCar, setSelectedCar] = useState(null)
  const [selectedContact, setSelectedContact] = useState(null)

  const { dealership }: any = useContext(DealershipContext)

  useEffect(() => {
    if (selectedCar && selectedContact) {
      onCreateTestDrive({
        carId: selectedCar.value,
        contactId: selectedContact.value,
      })
    }
  }, [selectedCar, selectedContact])

  return (
    <div>
      <div className="d-flex flex-wrap justify-content-between" style={{ columnGap: '8px' }}>
        <div className="flex-fill md-my-4">
          <div id="select-contact" className="card mb-4">
            <div className="card-header">Select a Customer</div>
            <div className="card-body">
              <SelectFetch
                value={selectedContact}
                url={Routes.dealership_contacts_path(dealership.id) + '.json'}
                mapResponse={(response: any) => {
                  return {
                    options: response.map((contact: { id: string; name: string }) => ({
                      value: contact.id,
                      label: contact.name,
                    })),
                    hasMore: false,
                  }
                }}
                onChange={setSelectedContact}
              />
            </div>
          </div>
        </div>
        <div className="flex-fill md-my-4">
          <div id="select-car" className="card mb-4">
            <div className="card-header">Select a Vehicle</div>
            <div className="card-body">
              <SelectFetch
                value={selectedCar}
                url={Routes.dealership_cars_path(dealership.id) + '.json'}
                mapResponse={(response: any) => ({
                  options: response,
                  hasMore: false,
                })}
                onChange={setSelectedCar}
                queryParams={{ for_dropdown: 1 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
