import { useState } from 'react'
import { humanize } from '../entries/utils'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { standardHeaders } from '../entries/utils'
import { ownerContext } from './utils'

const Form = ({ event, setLeadEvents, label }) => {
  const { owner, setOwner } = ownerContext()
  let [loading, setLoading] = useState(false)
  let [description, setDescription] = useState('')
  let [errors, setErrors] = useState(null)

  const onSubmit = () => {
    if (description.length === 0) {
      return
    }

    const url = (owner.model_name === 'LeadCluster') ? `/lead_clusters/${owner.id}/lead_events` : `/leads/${owner.id}/lead_events`

    setLoading(true)
    fetch(url, {
      method: 'POST',
      headers: standardHeaders,
      body: JSON.stringify({
        lead_event: {
          event_type: event,
          description: description,
        }
      }),
    }).then(response => response.json()).then(data => {
      if (data.errors) {
        console.log(data)
        setErrors(data.errors)

        throw new Error('An error occured')
      }

      setLoading(false)
      setLeadEvents(data.leadEvents)
      setOwner && setOwner({ ...owner, event_counts: data.event_counts })
      setDescription('')
    }).catch(error => {
      setLoading(false)
      console.error(error)
    })
  }

  const disabled = loading || description.length === 0

  return (
    <div className="p-3">
      <div className="form-group">
        <label htmlFor="lead_event_description">
          {label ? label : <>{humanize(event)} event details</>}
        </label>
        <textarea
          className="form-control"
          id="lead_event_description"
          rows="3"
          value={description}
          onChange={e => setDescription(e.target.value)}
          placeholder={`Describe your ${humanize(event)} here: eg. 'Called customer, will call back tomorrow'`}
        />
        {errors && (
          <div className="text-danger mt-2">
            {errors}
          </div>
        )}
      </div>
      <div className={`btn btn-outline-primary btn-block${disabled ? ' disabled' : ''}`} onClick={onSubmit} disabled={description.length === 0} id="add-event-btn">
        {loading ? (
          <div className="d-flex justify-content-center"><div className="loading-spinner"></div></div>
        ) : "Add Event"}
      </div>
    </div>
  )
}

const LeadEventForm = ({ setLeadEvents }) => {
  return (
    <Tabs>
      <div className="p-2 border-bottom lead-event-form">
        <TabList>
          <Tab>
            <i className="fa fa-envelope mr-1"></i>
            Email
          </Tab>
          <Tab>
            <i className="fa fa-phone mr-1"></i>
            Phone Call
          </Tab>
          <Tab>
            <i className="fa fa-sms mr-1"></i>
            SMS
          </Tab>
          <Tab>
            <i className="fa fa-note mr-1"></i>
            Notes
          </Tab>  
        </TabList>
      </div>
      <TabPanel>
        <Form event="email" setLeadEvents={setLeadEvents} label="Email Details" />
      </TabPanel>
      <TabPanel>
        <Form event="phone" setLeadEvents={setLeadEvents} label="Phone Call Details" />
      </TabPanel>
      <TabPanel>
        <Form event="sms" setLeadEvents={setLeadEvents} label="SMS Details" />
      </TabPanel>
      <TabPanel>
        <Form event="note" setLeadEvents={setLeadEvents} label="Notes" />
      </TabPanel>
    </Tabs>
  )
}

export default LeadEventForm