import { useContext } from 'react'
import { LeadContext } from '../../contexts'

const TestDriveButton = () => {
  const { lead, features, notification } = useContext(LeadContext)

  const testDriveStatuses = {
    Ready: 'Start Test Drive',
    'In Progress': 'Complete Test Drive',
    Completed: 'View Test Drive',
  }

  const carAvailableForTestDrives = lead?.car?.test_drive_in_progress_lead_id
    ? lead?.car?.test_drive_in_progress_lead_id === lead?.id
    : true
  const supportsTestDrive =
    features['test_drives'] &&
    ['New Vehicle Enquiry', 'Used Vehicle Enquiry'].includes(lead?.category) &&
    lead?.car?.id

  // For legacy leads that don't have a test drive, this generates it and redirects to the test drive page
  const generateTestDrive = async () => {
    const url = `/dealerships/${lead.dealership_id}/test_drives.json`
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content')

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrf,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        test_drive: {
          lead_id: lead?.id,
          contact_id: lead?.contact_id,
          car_id: lead?.car?.id,
          location_id: lead?.location_id,
          dealership_id: lead?.dealership_id,
        },
      }),
    })

    if (response.ok) {
      const data = await response.json()
      window.location.href = `/dealerships/${lead.dealership_id}/test_drives/${data.id}/edit`
    } else {
      notification.current.show({ severity: 'error', summary: 'Error', detail: 'Data not saved' })
    }
  }

  if (supportsTestDrive && carAvailableForTestDrives && lead?.test_drive_id) {
    return (
      <a
        href={`/dealerships/${lead.dealership_id}/test_drives/${lead.test_drive_id}/edit`}
        className="btn btn-outline-primary btn-block"
      >
        <i className="fa fa-steering-wheel mr-2"></i>
        {testDriveStatuses[lead.test_drive_status]}
      </a>
    )
  } else if (supportsTestDrive && carAvailableForTestDrives) {
    return (
      <button className="btn btn-outline-primary btn-block" onClick={generateTestDrive}>
        <i className="fa fa-steering-wheel mr-2"></i>
        Start Test Drive
      </button>
    )
  } else {
    return null
  }
}

export default TestDriveButton
