import { useContext } from 'react'
import { Dialog } from "primereact/dialog"
import { useState } from "react"
import LeadEventsContainer from './LeadEvents'
import { LeadClusterContext } from '../contexts';

const LeadEventStats = () => {
  const { leadCluster } = useContext(LeadClusterContext)

  if (!leadCluster) return <></>
  let [visible, setVisible] = useState(false)

  const openDialog = () => {
    setVisible(true)
  }

  const Wrapper = ({ children }) => (
    <div className="btn btn-sm mr-1 position-relative event-stat" onClick={openDialog}>
      {children}
    </div>
  )

  return (
    <>
      <Wrapper>
        {leadCluster.event_counts?.email && <span className="event-count">{leadCluster.event_counts.email}</span>}
        <i className="fa fa-envelope mr-1" />
      </Wrapper>
      <Wrapper>
        {leadCluster.event_counts?.phone && <span className="event-count">{leadCluster.event_counts.phone}</span>}
        <i className="fa fa-phone mr-1" />
      </Wrapper>
      <Wrapper>
        {leadCluster.event_counts?.sms && <span className="event-count">{leadCluster.event_counts.sms}</span>}
        <i className="fa fa-comment mr-1" />
      </Wrapper>
      <Wrapper>
        <i className="fa fa-car mr-1" />
      </Wrapper>
      <Dialog
        header="Event Stats"
        visible={visible}
        style={{ width: '50vw' }}
        breakpoints={{ '960px': '75vw', '641px': '100vw' }}
        onHide={() => setVisible(false)}
        dismissableMask={true}
      >
        <LeadEventsContainer />
      </Dialog>
    </>
  )
}

export default LeadEventStats