import { useState, useEffect, useContext } from 'react'
import { Tooltip } from 'react-tooltip'
import { format } from 'date-fns';
import GenerateAiReply from './GenerateAiReply'
import { formatPhone } from '../entries/utils'
import { standardHeaders } from '../entries/utils';
import { ownerContext } from './utils'

const Message = ({ text }) => {
  const formattedDate = format(new Date(text.created_at), 'dd/MM/yyyy HH:mm');

  return (
    <>
      <p className={(text.direction == 'inbound' ? "from-them" : "from-me") + (` ${text.status}`)} id={`text-${text.id}`}>
        <span>{text.text}</span>
      </p>
      <Tooltip
        anchorSelect={`#text-${text.id}`}
        place="bottom"
        style={{ zIndex: 10000 }}
      >
        <small>{formattedDate}</small>
        <div className="small">{text.status}</div>
        {text.user && <div className="small">Sent By: {text.user?.name}</div>}
      </Tooltip>
    </>
  )
}

const SmsConversation = () => {
  const { owner, setOwner } = ownerContext()

  let [message, setMessage] = useState('')
  let [texts, setTexts] = useState(owner.texts)
  let [loading, setLoading] = useState(false)
  let [aiReply, setAiReply] = useState('')

  useEffect(() => {
    if (aiReply) {
      setMessage(aiReply)
    }
  }, [aiReply])

  useEffect(() => {
    setOwner({ ...owner, texts: texts })
  }, [texts])

  const onSubmit = () => {
    setLoading(true)
    fetch(`/api/dealership/${owner.dealership_id}/texts`, {
      method: 'POST',
      headers: standardHeaders,
      body: JSON.stringify({
        content: message,
        message_type: 'sms',
        phone: owner.contact.phone_number,
        from: owner.dealership?.sms_phone_number,
        contact_id: owner.contact_id,
        //lead_id: owner.id,
        service: owner.dealership?.sms_phone_number === '0498025203' ? 'clicksend' : 'plivo',
        user_id: window.current_user.id
      })
    }).then(response => response.json()).then(data => {
      setTexts([...texts, data.text])
      setMessage('')
      setLoading(false)
    }).catch(error => {
      console.error('Error:', error)
      setLoading(false)
    })
  }

  let openingMessage = owner.message
  if (owner.message == null || owner.message == '') {
    openingMessage = `${owner.contact.first_name} submitted a ${owner.category || 'lead'}`
  }

  return (
    <div className="mx-3 mt-2 mb-2">
      <div className="border rounded mb-2">
        <div className="imessage mb-0">
          <p className="from-them">{openingMessage}</p>
          {texts.map(text => (
            <Message key={text.id} text={text} />
          ))}
        </div>
      </div>
      {owner.accepted_category_for_ai && (
        <GenerateAiReply aiReply={aiReply} setAiReply={setAiReply} />
      )}
      <div className="border rounded bg-whit message-form d-flex">
        <textarea
          placeholder="Type Your Message..."
          onChange={e => setMessage(e.target.value)}
          value={message}
          maxLength="320"
        />
        <button disabled={message === '' || loading} onClick={onSubmit}>
          {loading ? 'Loading...' : 'Send'}
        </button>
      </div>
      <div className='text-center'>
        <small className="text-secondary mt-2">
          SMS will be sent from <b>{formatPhone(owner.dealership?.sms_phone_number)}</b>. If you would like a dedicated mobile number, please contact support.
        </small>
      </div>
    </div>
  )
}

export default SmsConversation