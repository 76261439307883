import { useState } from 'react'
import { Tooltip } from 'react-tooltip'
import moment from 'moment'
import { CurrencyInput } from '../entries/FormElements'
import { updateCar } from './utils'
import CarScore from './CarScore'
import { Dialog } from 'primereact/dialog';

const PriceHistory = ({ hit, priceHistory }) => {
  let attribute = hit.price_type === 'Drive Away' ? 'dapprice' : 'egcprice'
  let canShowMargin = priceHistory.purchase_cost && attribute == 'egcprice'
  return (
    <table className="table table-sm text-white small table-borderless">
      <thead>
        <tr>
          <th>Date</th>
          <th>Price</th>
          {priceHistory.purchase_cost && <th>Cost</th>}
          {canShowMargin && <th>Est Margin</th>}
          {hit.dealership_slug === 'gasmak-motor-group' && (<th>Gasmak</th>)}
        </tr>
      </thead>
      <tbody>
        {priceHistory.price_changes.filter(change => change[attribute]).map((change, i) => {
          // divided by 1.1 to remove GST
          let margin = change[attribute][1] / 1.1 - priceHistory.purchase_cost
          return (
            <tr key={`${hit.id}-car-history-change-${i}`}>
              <td>{moment.utc(change.updated_at[1]).format("DD/MM")}</td>
              <td>${change[attribute][1]?.toLocaleString()}</td>
              {priceHistory.purchase_cost && <td>${priceHistory.purchase_cost.toLocaleString()}</td>}
              {canShowMargin && <td className={margin < 0 ? 'text-danger' : 'text-success'}>
                {margin.toLocaleString('en-US', { maximumFractionDigits: 0, currencyDisplay: 'narrowSymbol', style: 'currency', currency: 'AUD', })}
              </td>}
              {(hit.dealership_slug === 'gasmak-motor-group') && (
                <td>${priceHistory.gasmak_cost.toLocaleString()}</td>
              )}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const price = (price) => {
  if (!price) return price

  return price.toLocaleString('en-US', {
    maximumFractionDigits: 0,
    currencyDisplay: 'narrowSymbol',
    style: 'currency',
    currency: 'AUD'
  })
}

const SelectButton = ({ value, onChange, options }) => {
  return (
    <div className="btn-group">
      {options.map((o, i) => (
        <button
          key={i}
          onClick={() => onChange({ value: o })}
          className={`btn btn-sm ${value === o ? 'btn-primary' : 'btn-outline-secondary'}`}
        >
          {o}
        </button>
      ))}
    </div>
  )
}

const CarPriceModal = ({ hit, setCar, visible, setVisible }) => {
  let [dapprice, setDapprice] = useState(hit.dapprice)
  let [egcprice, setEgcprice] = useState(hit.egcprice)
  let [beforePrice, setBeforePrice] = useState(hit.before_price)
  let [advertisedPrice, setAdvertisedPrice] = useState(hit.advertised_price || 'DAP')
  let [errors, setErrors] = useState(false)
  let [loading, setLoading] = useState(false)

  const Submit = () => {
    updateCar({
      car: hit,
      fields: {
        dapprice: dapprice,
        egcprice: egcprice,
        before_price: beforePrice,
        advertised_price: advertisedPrice
      },
      setCar: setCar,
      setErrors: setErrors,
      setLoading: setLoading,
      afterUpdate: () => {
        setVisible(false)
      }
    })
  }

  return (
    <Dialog
      header={`Edit ${hit.name} Price`}
      visible={visible}
      style={{ width: '50vw' }}
      onHide={() => setVisible(false)}
      dismissableMask={true}
    >
      {errors && <p className="text-danger">{errors}</p>}
      <CurrencyInput name="before_price" onChange={e => setBeforePrice(e.target.rawValue)} placeholder="Before Price" value={beforePrice || ''} />
      {hit.source === "manual" && (
        <>
          <CurrencyInput name="dapprice" onChange={e => setDapprice(e.target.rawValue)} placeholder="Drive Away Price" value={dapprice || ''} />
          <CurrencyInput name="egcprice" onChange={e => setEgcprice(e.target.rawValue)} placeholder="Excluding Government Charges Price" value={egcprice || ''} />
        </>
      )}
      {hit.price && hit.price > 0 && hit.dapprice && hit.egcprice ? (
        <div className="mb-2">
          <div><b>Advertised Car Price</b></div>
          <div className="d-flex align-items-center mt-2">
            <SelectButton value={advertisedPrice} onChange={(e) => setAdvertisedPrice(e.value)} options={["DAP", "EGC"]} />
            <div className="ml-2">
              Car will be advertised as {advertisedPrice === 'DAP' ? price(hit.dapprice) : price(hit.egcprice)} {advertisedPrice}
            </div>
          </div>
        </div>
      ) : ''}
      <button onClick={() => Submit()} className={`btn btn-outline-success btn-block mt-2 ${loading ? 'disabled' : ''}`} disabled={loading}>
        {loading ? <><i className='fa fa-circle-notch fa-spin mr-2' />Loading...</> : "Update"}
      </button>
    </Dialog>
  )
}

const CarPrice = ({ hit, setCar }) => {
  let [priceHistory, setPriceHistory] = useState(false)
  let [visible, setVisible] = useState(false)

  const loadData = () => {
    if (priceHistory) {
      return
    }
    fetch(`/cars/${hit.id}/price_changes`)
      .then(res => res.json())
      .then(res => setPriceHistory(res))
  }

  return (
    <>
      <div className="d-flex">
        <div id={`price-${hit.id}`} onClick={() => setVisible(true)} role="button">
          {hit.before_price && hit.before_price > 0 && hit.before_price > hit.price && (
            <div className="small text-secondary" style={{ textDecoration: "line-through" }}>
              ${hit.before_price.toLocaleString()}
            </div>
          )}
          ${(hit.price || 0).toLocaleString()}
          <div className="small text-secondary">
            {hit.price_type && hit.price_type.match(/\b([A-Z])/g).join('')}
          </div>
        </div>
        {hit.autograb_data?.average_market_price && (hit.price < hit.autograb_data?.average_market_price ? (
          <div className="text-success ml-1">
            <i className="fa fa-arrow-down" id={`autograb-price-${hit.id}`} />
          </div>
        ) : (
          <div className="text-danger ml-1">
            <i className="fa fa-arrow-up" id={`autograb-price-${hit.id}`} />
          </div>
        ))}
      </div>
      {hit.subscription_price && (
        <div className="text-secondary">
          ${hit.subscription_price.toLocaleString()}/wk
        </div>
      )}
      <div>
        {hit.autograb_data?.car_grade && (
          <CarScore hit={hit} />
        )}
      </div>
      <CarPriceModal
        hit={hit}
        setCar={setCar}
        visible={visible}
        setVisible={setVisible}
      />
      <Tooltip
        anchorSelect={`#price-${hit.id}`}
        place="bottom-start"
        style={{ zIndex: 10000 }}
        afterShow={loadData}
      >
        {priceHistory.price_changes ? (
          <>
            {hit.price > 0 && priceHistory.price_changes.length > 1 ?
              <PriceHistory priceHistory={priceHistory} hit={hit} />
              : (
                <div className="small">No Price Changes</div>
              )}
          </>
        ) : (
          <div className="small">Loading...</div>
        )}
      </Tooltip>
      <Tooltip
        anchorSelect={`#autograb-price-${hit.id}`}
        place="bottom-start"
        style={{ zIndex: 10000 }}
      >
        {hit.price < hit.autograb_data?.average_market_price ? (
          <b className="text-success">Below Market Price</b>
        ) : (
          <b className="text-danger">Above Market Price</b>
        )}
        <div>National average is <b>${hit.autograb_data?.average_market_price?.toLocaleString()}</b></div>
        {hit.autograb_data?.state_average_market_price && <div>{hit.rego_state || 'State'} average is <b>${hit.autograb_data?.state_average_market_price?.toLocaleString()}</b></div>}
      </Tooltip>
    </>
  )
}

export default CarPrice