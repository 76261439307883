const CreditScoreRequest = ({ creditScoreRequest }) => {
  return (
    <div className="px-3 py-1">
      <div className="box p-3">
        <div className="d-flex justify-content-between">
          <div className="mr-auto">
            <h5>Equifax Credit Score</h5>
            <div className="small text-secondary">
              <b>One Score Credit Score: </b>
              <div className="badge badge-secondary">
                {creditScoreRequest?.credit_score?.scores?.one_score.masterscale}
              </div>
            </div>
          </div>
          <div>
            <a className="btn btn-outline-secondary btn-sm mt-2" href={`/credit_score_requests/${creditScoreRequest.id}/download/request`}>
              <i className="fas fa-download mr-2"></i>
              Request Payload
            </a>
            <a className="btn btn-outline-secondary btn-sm ml-2 mt-2" href={`/credit_score_requests/${creditScoreRequest.id}/download/response`}>
              <i className="fas fa-download mr-2"></i>
              Response Payload
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreditScoreRequest