import { useContext } from 'react';
import { LeadContext, LeadClusterContext } from '../contexts';
import { standardHeaders } from '../entries/utils';

export const updateLeadAPI = (leadId, data, modelName = 'leadCluster') => {

  let url = `${window.location.origin}/dealerships/${window.dealer_slug}/lead_clusters/${leadId}`

  return fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: standardHeaders,
  })
}

export const updateLeadUserId = (leadId, userId, modelName) => {
  let data = {
    lead_cluster: {
      user_id: userId
    }
  }

  return updateLeadAPI(leadId, data, modelName)
}

export const updateLeadState = (leadId, leadState, modelName) => {
  let data = {
    lead_cluster: {
      lead_state: leadState
    }
  }
  return updateLeadAPI(leadId, data, modelName)
}

export const updateContactAPI = (contactId, data) => {
  return fetch(`${window.location.origin}/dealerships/${window.dealer_slug}/contacts/${contactId}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: standardHeaders,
  })
}

export const ownerContext = () => {
  const { lead, setLead } = useContext(LeadContext);
  const { leadCluster, setLeadCluster } = useContext(LeadClusterContext);

  // Check if lead is present, otherwise use leadCluster
  return {
    owner: lead ?? leadCluster,
    setOwner: lead ? setLead : setLeadCluster
  };
};