import { useEffect, useState } from 'react'
import Select from 'react-select'
import Loading from '../Loading'
import KanbanBoard from './Kanban/KanbanBoard'
import { useParams } from 'react-router-dom'

const CustomHitsBeta = () => {
  let [data, setData] = useState(false)
  let [onlyMe, setOnlyMe] = useState(false)
  let [users, setUsers] = useState([])
  let [loading, setLoading] = useState(false)
  let [categories, setCategories] = useState([])
  let [selectedCategories, setSelectedCategories] = useState([])
  let [selectedLocations, setSelectedLocations] = useState([])
  let { pipelineId } = useParams()

  const refreshData = () => {
    setLoading(true)
    let url = `/dealerships/${window.dealer_slug}/leads/trello_data.json?only_me=${onlyMe}&user_ids=${users}&categories=${selectedCategories}&location_ids=${selectedLocations}`

    if (pipelineId !== undefined && pipelineId !== null) {
      url += `&pipeline_id=${pipelineId}`;
    }


    fetch(url)
      .then(response => response.json())
      .then(data => {
        setData(data)
        if (categories.length === 0) setCategories(data.categories?.sort((a, b) => a.localeCompare(b)))
        setLoading(false)
      })
  }

  useEffect(() => {
    refreshData()
  }, [onlyMe, users, selectedCategories, selectedLocations])

  if (!data) return (
    <div className="py-3">
      <Loading />
    </div >
  )

  const toggleValue = () => {
    setOnlyMe(!onlyMe)
  }

  return (
    <div className="py-3">
      <p className="mb-2 text-secondary">Only showing leads from last 30 days</p>
      <div className="box p-2 d-flex align-items-center">
        <div className="custom-control custom-switch mr-3">
          <input
            type="checkbox"
            className={"custom-control-input" + (loading ? " disabled" : "")}
            checked={onlyMe}
            onChange={toggleValue}
            disabled={loading}
            id={`switch-only-me`}
          />
          <label
            className="custom-control-label"
            htmlFor={`switch-only-me`}
          >
            Only show my leads
          </label>
        </div>
        {window.dealer_slug === 'bellbowrie-motors' && (
          <div className="d-flex">
            <div className="mr-3">
              <Select
                options={categories.map(category => ({ value: category, label: category }))}
                onChange={(e) => setSelectedCategories(e.map(e => e.value))}
                isMulti
                placeholder="Filter by Category"
              />
            </div>
            <div className="mr-3">
              <Select
                options={data.locations.map(location => ({ value: location.id, label: location.name }))}
                onChange={(e) => setSelectedLocations(e.map(e => e.value))}
                isMulti
                placeholder="Filter by Location"
              />
            </div>
          </div>
        )}
        <div className="ml-auto" style={{ marginRight: '10px' }}>
          <Select
            options={data.users.map(user => ({ value: user.id, label: user.name }))}
            onChange={(e) => setUsers(e.map(user => user.value))}
            isMulti
            placeholder="Filter by user"
          />
        </div>
      </div>
      <KanbanBoard columns={data.columns} leads={data.lead_clusters} refreshData={refreshData} />
    </div>
  )
}

export default CustomHitsBeta
