// MANAGED BY App.js
import { useState, useEffect, useRef, useContext } from 'react'
import LeadEventsContainer from './LeadEvents'
import Apicall from './Apicall'
import DealerEmail from './DealerEmail'
import Email from './Email'
// import CarsAlsoViewed from './CarsAlsoViewed'
import CarBox from './CarBox'
import ModelBox from './ModelBox'
import LeadPayment from './LeadPayment'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import SmsConversation from './SmsConversation'
import LeadSidebar from './LeadSidebar'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { Skeleton } from 'primereact/skeleton'
import CreditScoreRequest from './CreditScoreRequest'
import { Toast } from 'primereact/toast'
import { DealershipContext, LeadContext } from '../contexts'
import { useFetchDealership } from '../dataHooks'

const TabOne = () => {
  const { lead } = useContext(LeadContext)
  const { emails, dealer_emails, apicalls } = lead

  return (
    <>
      {emails && emails.map((email) => <Email email={email} key={email.id} />)}
      {dealer_emails && dealer_emails.map((dealerEmail) => <DealerEmail dealerEmail={dealerEmail} key={dealerEmail.id} />)}
      {apicalls && apicalls.map((apicall) => <Apicall apicall={apicall} key={apicall.id} />)}
      {lead.creditScoreRequests && lead.creditScoreRequests.map((creditScoreRequest) => <CreditScoreRequest creditScoreRequest={creditScoreRequest} key={creditScoreRequest.id} />)}
      <LeadEventsContainer />
    </>
  )
}

const LeadMain = () => {
  const navigate = useNavigate()

  const handleBack = () => {
    // Go back to the previous page
    navigate(-1)
  }
  const { lead } = useContext(LeadContext)

  return (
    <>
      <div className="px-3 pt-1">
        <div className="btn btn-outline-secondary btn-block-md-down mr-auto mb-2" onClick={handleBack}>
          <i className="fas fa-arrow-left mr-2"></i>
          Back
        </div>
        {lead.spam_score > 2 && <div className="mb-2 box p-3 alert-danger">
          This lead has been automatically marked as spam as it has a spam score of {lead.spam_score}. If this is incorrect, please contact support.
        </div>}
        {lead.payment && <LeadPayment />}
        {lead.car && <CarBox />}
        {lead.model && <ModelBox />}
      </div>
      <Tabs>
        <div className="px-3 py-1">
          <div className="rounded border overflow-hidden lead-tabs">
            <TabList>
              <div className="d-flex">
                <Tab>Lead Events</Tab>
                <Tab>
                  <div id="sms-conversation-button">
                    SMS Conversation ({lead.texts?.length}) <div className="badge badge-danger">BETA</div>
                  </div>
                </Tab>
              </div>
            </TabList>
          </div>
        </div>
        <div className="px-3">
          {lead.visited_pages?.length > 0 && (
            <p className="small mb-1 mt-2 text-secondary text-center">
              {lead.name} visited {lead.visited_pages.length} pages before submitting lead
            </p>
          )}
        </div>
        <TabPanel>
          <div className="px-3">
            {lead.leadDetails && (
              <div
                className="my-1 border bg-white rounded p-3"
                style={{ overflowX: 'scroll' }}
                dangerouslySetInnerHTML={{ __html: lead.leadDetails }}
              />
            )}
          </div>
          <TabOne></TabOne>
        </TabPanel>
        <TabPanel>
          <SmsConversation />
        </TabPanel>
      </Tabs>
    </>
  )
}

export default function Show() {
  const { leadId } = useParams()
  const { state } = useLocation()
  const [status, setStatus] = useState('loading')
  const rootElement = document.getElementById('app_root')
  const testDrivesEnabled = rootElement?.getAttribute('test_drives_enabled') === 'true'
  const features = { 'test_drives': testDrivesEnabled }
  const notification = useRef(null)
  let [lead, setLead] = useState(state)
  const { dealership } = useContext(DealershipContext)

  useFetchDealership()

  useEffect(() => {
    if (status !== 'completed' && dealership?.slug) {
      fetch(`${window.location.origin}/dealerships/${dealership.slug}/leads/${leadId}.json`).then((response) => response.json()).then((data) => {
        setLead(data)
        setStatus('completed')
      })
    }
  }, [dealership?.slug])

  useEffect(() => {
    document.body.scrollTo(0, 0)
  })

  return (
    <LeadContext.Provider value={{ lead, features, setLead, notification }}>
      <div className="row no-gutters h-100">
        <div className="col-md-9">
          <div className="py-3">
            {status === 'completed' ? <LeadMain /> : (
              <div className="px-3 pt-1 py-3">
                <Skeleton
                  width="10rem"
                  className="mb-2 w-100"
                  height="10rem"
                />
                <Skeleton
                  width="10rem"
                  className="mb-2 w-100"
                  height="10rem"
                />
                <Skeleton
                  width="10rem"
                  className="mb-2 w-100"
                  height="10rem"
                />
              </div>
            )}
          </div >
        </div>
        <Toast ref={notification} />
        <LeadSidebar
          status={status}
          notification={notification}
        />
      </div>
    </LeadContext.Provider>
  )
}