import { useState, useEffect } from 'react'
import SlideIn from '../shared/SlideIn'
import Loading from '../Loading'
import { OramaProvider } from 'use-orama';
import SlideOutHeading from './SlideOutHeading'
import Cars from './Autograb/Cars'

const schema = { name: 'string', state: 'string' };

const Stat = ({ stat, description }) => (
  <div className="text-center border rounded p-1">
    <h3 className="mb-0">{stat}</h3>
    <span className="small text-secondary">{description}</span>
  </div>
)

const Autograb = ({ autograb, setAutograb }) => {
  let [marketOverlay, setMarketOverlay] = useState(false)
  let [loading, setLoading] = useState(false)
  let [errors, setErrors] = useState(false)
  let [slideInOpen, setSlideInOpen] = useState(false)

  useEffect(() => {
    if (autograb) {
      setLoading(true)
      setErrors(false)
      fetch(`/cars/${autograb.slug}/market_overlay.json`)
        .then(res => res.json())
        .then(res => {
          setLoading(false)
          if (res.success) {
            setMarketOverlay(res)
          } else {
            setErrors(res.message || res.error)
          }
        })
        .catch(err => {
          setLoading(false)
          setErrors('An error has occured.')
        })
    } else {
      setMarketOverlay(false)
    }
  }, [autograb]);

  return (
    <SlideIn isOpen={autograb} setIsOpen={setAutograb} afterOpen={() => setSlideInOpen(true)} afterClose={() => setSlideInOpen(false)}>
      <h4>Market Overlay</h4>
      {autograb && (
        <SlideOutHeading car={autograb} />
      )}
      {(loading || !slideInOpen) ? (
        <Loading />
      ) : (
        <OramaProvider schema={schema}>
          <div className="row mb-3 w-100">
            <div className="col-md-3">
              <Stat stat={marketOverlay.avg_days_to_sell} description="Average days to sell" />
            </div>
            <div className="col-md-3">
              {marketOverlay.average_price && <Stat stat={`$${marketOverlay.average_price?.toLocaleString()}`} description="Average Price" />}
            </div>
            <div className="col-md-3">
              <Stat stat={marketOverlay.average_kms?.toLocaleString()} description="Average Kms" />
            </div>
            {marketOverlay.price_rank ? (<div className="col-md-3">
              <Stat stat={`${marketOverlay.price_rank?.toLocaleString()}/${marketOverlay.sample_size}`} description="Price Rank in AU" />
            </div>) : ''}
          </div>

          {marketOverlay && <Cars cars={marketOverlay.leads.filter(c => c.state)} car={autograb} />}
        </OramaProvider>
      )}
      {errors && (
        <p className="text-danger">{errors}</p>
      )}
    </SlideIn>
  )
}

export default Autograb