import { useState } from 'react'
import { Panel } from 'primereact/panel'
import { Dialog } from 'primereact/dialog'
import moment from 'moment'
import DealerEmail from '../leads/DealerEmail'
import Email from '../leads/Email'
import Apicall from '../leads/Apicall'
import CarsAlsoViewed from '../leads/CarsAlsoViewed'

const Emails = ({ lead }) => {
  let [visible, setVisible] = useState(false)
  const { emails, dealer_emails, apicalls } = lead

  return (
    <>
      <div className="btn btn-outline-secondary btn-sm ml-2" onClick={() => setVisible(true)}>
        <i className="fa fa-envelope"></i>
        <span className="ml-2">
          {emails.length + dealer_emails.length + apicalls.length}
        </span>
      </div>
      <Dialog
        header="Emails/API calls"
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
        dismissableMask={true}
      >
        {emails?.map((email) => <Email email={email} key={email.id} />)}
        {apicalls?.map((apicall) => <Apicall apicall={apicall} key={apicall.id} />)}
        {dealer_emails?.map((dealerEmail) => <DealerEmail dealerEmail={dealerEmail} key={dealerEmail.id} />)}
      </Dialog>
    </>
  )
}

const LeadContainer = ({ lead, position }) => {

  const Header = () => (
    <div>
      Lead {position + 1}: {lead.category}
      <small className="text-secondary">
        <span> - {moment(lead.created_at).fromNow()}</span>
        {lead.source && <span> from {lead.source}</span>}
      </small>
    </div>
  )

  const Footer = () => (
    <div className="d-flex">
      <div>
        <a href={`/dealerships/${lead.dealership_id}/leads/${lead.id}/edit`} className="btn btn-outline-secondary btn-sm">
          <i className="fa fa-edit mr-2"></i>
          Edit
        </a>
        <Emails lead={lead} />
      </div>
      <div className="ml-auto text-secondary">
        {moment(lead.created_at).format('h:mm a')}
      </div>
    </div>
  )

  return (
    <Panel header={Header} footer={Footer} toggleable collapsed={position > 2} className='mb-3'>
      {lead.car && (
        <p>
          Lead submitted on a {lead.car.name}
        </p>
      )}
      <div
        style={{ overflowX: 'scroll' }}
        dangerouslySetInnerHTML={{ __html: lead.leadDetails }}
      />
      {lead.carsAlsoViewed?.length > 0 && <CarsAlsoViewed lead={lead} />}
    </Panel>
  )
}

export default LeadContainer