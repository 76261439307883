import { useState, useEffect } from 'react'
import moment from 'moment'
import { humanize } from '../entries/utils'

let attributeNames = {
  dapprice: 'Drive Away Price',
  egcprice: 'EGC price',
  dealercomments: 'dealer comments',
  regplate: 'registration',
  km: 'Odometer',
  load_to_autogate: 'load to Autogate',
  load_to_autotrader: 'load to Autotrader',
  custom_features: 'custom features',
  trans: 'transmission',
}

const attributeDisplay = (key, value) => {
  if (value && (key === 'dapprice' || key === 'egcprice')) {
    return `$${value.toLocaleString()}`
  }

  return (
    `"${value?.toString()}"`
  )
}

const Changes = ({ car }) => {
  let [versions, setVersions] = useState([])
  let [loading, setLoading] = useState(true)

  useEffect(() => {
    if (versions.length == 0) {
      setLoading(true)
      fetch(`/cars/${car.id}/changes`)
        .then(res => res.json())
        .then(data => {
          setVersions(data)
          setLoading(false)
        })
    }
  }, [])

  return (
    <div className="p-3">
      <h4>Changes</h4>
      {loading && <p>Loading...</p>}
      {versions.length > 0 && versions.map(version => (
        <div className="box p-3 mb-2" key={version.version.id}>
          <div className='float-right'>
            {moment(version.version.created_at).utc().add(10, 'hours').format('DD/MM/YY h:mm A')}
          </div>
          <ul className="mb-0">
            {Object.keys(version.changeset).map((key) => (
              <li className="text-secondary" key={key}>
                {key === 'dealercomments' ? (
                  <>
                    {version.change_by} changed <b>{attributeNames[key] || humanize(key)}</b>
                    <ul>
                      <li><b>Before:</b> {attributeDisplay(key, version.changeset[key][0])} </li>
                      <li><b>After:</b> {attributeDisplay(key, version.changeset[key][1])}</li>
                    </ul>
                  </>
                ) : (
                  <>
                    {version.change_by} changed <b>{attributeNames[key] || humanize(key)}</b> from {attributeDisplay(key, version.changeset[key][0])} to {attributeDisplay(key, version.changeset[key][1])}
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
      ))}
      <div className="box p-3 mb-2">
        <div className='float-right'>
          {moment(car.created_at).utc().add(10, 'hours').format('DD/MM/YY h:mm A')}
        </div>
        <ul className="mb-0">
          <li className="text-secondary">
            Car created
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Changes