import { useContext } from 'react'
import moment from 'moment'
import { LeadContext } from '../contexts'
const LeadTestDrive = () => {
  const { lead } = useContext(LeadContext)
  let status = lead.test_drive_status

  return (
    <>
      {status === "Ready" && <div className="text-primary"><i className="fas fa-key"></i> Ready</div>}
      {status === "Completed" && <div className="text-success"><i className="fas fa-steering-wheel"></i> Completed</div>}
      {status === "In Progress" && (
        <div className="text-warning">
          <i className="fas fa-spinner"></i> In Progress
          <div className="text-secondary">
            {moment(lead.test_drive_updated_at).fromNow()}
          </div>
        </div>
      )}
    </>
  )
}

export default LeadTestDrive