import { useContext } from 'react'
import { LeadContext } from '../contexts'
const ModelBox = () => {
  const { lead } = useContext(LeadContext)

  return (
    <div className="border bg-white rounded p-3 mb-1">
      <div className="d-flex align-items-center">
        {lead.model.image && (
          <img src={lead.model.image_url} height="75" className="mr-3" alt={lead.model.name} />
        )}
        <div className="mr-auto">
          <h6 className="mb-0">{lead.model.make} {lead.model.name}</h6>
          <div className="text-secondary">
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModelBox;