
export const StockRule = ({ rule }) => (
  <div className="border-bottom p-3">
    <p><b>Action:</b> {rule.action}</p>
    <div
      dangerouslySetInnerHTML={{
        __html: rule.description,
      }}
    />
  </div>
)

const ThirdPartyRestriction = ({ ignore_manufacturer_restriction, handleThirdPartyRestriction, manufacturer, stock_rules }) => {

  const changeRestriction = (e) => {
    e.preventDefault()
    if (!ignore_manufacturer_restriction) {
      if (window.confirm('Are you sure you want to ignore the OEM rules?')) {
        handleThirdPartyRestriction()
      }
    } else {
      handleThirdPartyRestriction()
    }
  }

  return (
    <div className="box mt-3 mb-3">
      <div className="d-flex border-bottom p-3 align-items-center">
        <i className="fa fa-exclamation-triangle text-danger mr-1"></i>&nbsp;
        <span className="mb-0">{ignore_manufacturer_restriction ? <>{manufacturer} OEM restrictions apply however are <b>currently being ignored</b></> : `${manufacturer} OEM restrictions are in place`}</span>
        <div className="ml-auto">
          <a href="#" onClick={changeRestriction} className={`btn btn-sm ${ignore_manufacturer_restriction ? 'btn-outline-success' : 'btn-outline-danger'}`}>
            {ignore_manufacturer_restriction ? 'Follow Restrictions' : 'Ignore Restrictions'}
          </a>
        </div>
      </div>
      <div>
        {stock_rules.map((rule, index) => (
          <StockRule rule={rule} key={`stock-rule-${index}`} />
        ))}
      </div>
    </div>
  )
}

export default ThirdPartyRestriction;



