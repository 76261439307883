import { useRef, useCallback, useContext, useEffect } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { Toast } from 'primereact/toast'

import { CustomerVehicleData } from './components/TestDriveForm/CustomerVehicleData'
import Disclaimer from './components/TestDriveForm/Disclaimer'
import CustomerAgreement from './components/TestDriveForm/CustomerAgreement'
import { AgreementMethod } from './components/TestDriveForm/CustomerAgreement'
import { DriverLicenseUpload } from './components/TestDriveForm/DriverLicenseUpload'
import Timeline from './components/TestDriveForm/Timeline'
import Notes from './components/TestDriveForm/Notes'
import { DealershipContext, TestDriveContext } from '../contexts'
import { NewTestDrive } from './components/TestDriveForm/NewTestDrive'
import { useFetchDealership, useFetchTestDrive } from '../dataHooks'

type Inputs = {
  checkedInAt: Date | string
  checkedOutAt: Date | string
  insuranceExcess: number
  dealerComments: string
  contactId?: number
  carId?: number
}

export default function TestDriveForm() {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm<Inputs>()

  const insuranceExcess = watch('insuranceExcess')
  const { testDrive, setTestDrive }: any = useContext(TestDriveContext)
  const { dealership }: any = useContext(DealershipContext)
  const notification = useRef(null)
  useFetchTestDrive()
  useFetchDealership()

  useEffect(() => {
    if (testDrive) {
      const checked_in_at =
        testDrive.checked_in_at === null ? '' : new Date(testDrive.checked_in_at)
      const checked_out_at =
        testDrive.checked_out_at === null ? '' : new Date(testDrive.checked_out_at)

      reset({
        checkedInAt: checked_in_at,
        checkedOutAt: checked_out_at,
        insuranceExcess: testDrive.insurance_excess ?? insuranceExcess,
        dealerComments: testDrive.dealer_comments || '',
      })
    }
  }, [testDrive])

  const csrf = document.querySelector("meta[name='csrf-token']")?.getAttribute('content')

  const handleBack = () => {
    // If the referrer already has URL params, use &, other use ?
    window.location.href =
      document.referrer + (document.referrer.includes('?') ? '&' : '?') + 'refresh=1'
  }

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const url = `/dealerships/${dealership.id}/test_drives/${testDrive.id}.json`
    const formData = {
      insurance_excess: data.insuranceExcess,
      checked_in_at: data.checkedInAt,
      checked_out_at: data.checkedOutAt,
      dealer_comments: data.dealerComments,
      disclaimer_text: testDrive.disclaimer,
    }

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      body: JSON.stringify(formData),
    })

    if (response.ok) {
      notification.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Data saved successfully',
      })
      handleBack()
    } else if (response.status === 422) {
      response.json().then((data) => {
        const fieldNames = {
          checked_in_at: 'Check-in Date/time',
          checked_out_at: 'Check-out Date/time',
          insurance_excess: 'Insurance Excess',
          // Add more mappings as needed
        }

        const errors = Object.entries(data.errors).map(([key, value]) => {
          const fieldName = fieldNames[key] || key // Use the mapped name if it exists, otherwise use the original key
          return <li key={key}>{`${fieldName}: ${(value as string[]).join(', ')}`}</li>
        })

        notification.current.show({
          severity: 'error',
          summary: 'Error',
          content: () => (
            <div className="flex flex-column align-items-left" style={{ flex: '1' }}>
              <div className="font-medium text-lg my-3 text-900">{errors}</div>
            </div>
          ),
        })
      })
    }
  }

  const onCreateTestDrive: SubmitHandler<Inputs> = async (data) => {
    const url = `/dealerships/${dealership.id}/test_drives.json`
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const formData = {
      contact_id: data.contactId,
      car_id: data.carId,
      dealership_id: dealership.id,
      timezone,
    }
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      body: JSON.stringify(formData),
    })

    if (response.ok) {
      const testDriveData = await response.json()
      setTestDrive(testDriveData)
    } else {
      notification.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to create test drive',
      })
    }
  }

  const sendConfirmationMessage = async (method: AgreementMethod) => {
    if (method === 'email') {
      const url = `/dealerships/${dealership.id}/test_drives/${testDrive.id}/send_agreement_confirmation_email.json`
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf,
        },
      })

      if (response.ok) {
        notification.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Confirmation email sent',
        })
      } else if (response.status === 422) {
        const body = await response.json()
        notification.current.show({ severity: 'error', summary: 'Error', detail: body.message })
      }

      return response
    } else if (method === 'sms') {
    }
    // TODO: Implement SMS verification
  }

  const verifyConfirmationCode = async (code: string) => {
    const url = `/dealerships/${dealership.id}/test_drives/${testDrive.id}/verify_confirmation_code.json`
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      body: JSON.stringify({ confirmation_code: code }),
    })

    if (response.ok) {
      notification.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Confirmation code verified',
      })
      loadDisclaimerAgreedAt()
    } else if (response.status === 422) {
      const body = await response.json()
      notification.current.show({ severity: 'error', summary: 'Error', detail: body.message })
      return false
    }

    return response
  }

  const recordSignature = async (signature: string) => {
    const url = `/dealerships/${dealership.id}/test_drives/${testDrive.id}/record_signature.json`
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      body: JSON.stringify({ signature }),
    })

    if (response.ok) {
      notification.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Signature recorded',
      })
      loadDisclaimerAgreedAt()
    } else if (response.status === 422) {
      const body = await response.json()
      notification.current.show({ severity: 'error', summary: 'Error', detail: body.message })
      return false
    }

    return response
  }

  const uploadDriversLicense = async (file: File, type: string = 'front') => {
    const formData = new FormData()

    formData.append('file', file)

    try {
      const action = type === 'front' ? 'upload_drivers_license' : 'upload_drivers_license_back'
      const response = await fetch(
        `/dealerships/${dealership.id}/test_drives/${testDrive.id}/${action}`,
        {
          method: 'POST',
          headers: {
            'X-CSRF-Token': csrf,
            Accept: 'application/json',
          },
          body: formData,
        }
      )

      if (response.ok) {
        const json = await response.json()
        const updatedTestDrive =
          type === 'front'
            ? {
                ...testDrive,
                drivers_license_url: json.url,
                drivers_license_name: json.name,
              }
            : {
                ...testDrive,
                drivers_license_back_url: json.url,
                drivers_license_back_name: json.name,
              }
        setTestDrive(updatedTestDrive)
        notification.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Upload successful!',
        })
      } else {
        throw new Error('Failed to upload file')
      }
    } catch (error) {
      console.error('Upload failed:', error)
      notification.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Upload failed! Please try again.',
      })
    }
  }

  const deleteDriversLicense = async (type: string = 'front') => {
    const action = type === 'front' ? 'delete_drivers_license' : 'delete_drivers_license_back'

    try {
      const response = await fetch(
        `/dealerships/${dealership.id}/test_drives/${testDrive.id}/${action}`,
        {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': csrf,
            Accept: 'application/json',
          },
        }
      )

      if (response.ok) {
        const updatedTestDrive =
          type === 'front'
            ? { ...testDrive, drivers_license_url: '', drivers_license_name: '' }
            : { ...testDrive, drivers_license_back_url: '', drivers_license_back_name: '' }
        setTestDrive(updatedTestDrive)
        notification.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'File deleted successfully!',
        })
      } else {
        notification.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Delete failed! Please try again.',
        })
        throw new Error('Failed to delete file')
      }
    } catch (error) {
      notification.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Delete failed! Please try again.',
      })
    }
  }

  const loadDisclaimerAgreedAt = async () => {
    const url = `/dealerships/${dealership.id}/test_drives/${testDrive.id}.json`
    const response = await fetch(url)
    const data = await response.json()
    const updatedTestDrive = {
      ...testDrive,
      disclaimer_agreed_at: data.disclaimer_agreed_at ? new Date(data.disclaimer_agreed_at) : null,
    }
    setTestDrive(updatedTestDrive)
  }

  return (
    <div className="pt-3 pb-3 container-fluid">
      <div className="mb-3 d-flex align-items-center">
        <div className="btn btn-outline-secondary btn-block-md-down mr-2 mb-2" onClick={handleBack}>
          <i className="fas fa-arrow-left mr-2"></i>
          Back
        </div>
        <div>
          <h4>
            <i className="fa fa-steering-wheel"></i>
            {!testDrive ? ' New Test Drive' : 'Test Drive'}
          </h4>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Toast ref={notification} />
        {testDrive && (
          <>
            <CustomerVehicleData />
            <Disclaimer control={control} />
            {insuranceExcess && (
              <>
                <CustomerAgreement
                  sendConfirmationMessage={sendConfirmationMessage}
                  verifyConfirmationCode={verifyConfirmationCode}
                  recordSignature={recordSignature}
                />
                {testDrive.disclaimer_agreed_at && (
                  <>
                    <DriverLicenseUpload
                      uploadDriversLicense={uploadDriversLicense}
                      deleteDriversLicense={deleteDriversLicense}
                    />
                    {testDrive.drivers_license_url && testDrive.drivers_license_back_url && (
                      <>
                        <Timeline control={control} />
                        <Notes control={control} />
                      </>
                    )}
                  </>
                )}
              </>
            )}

            <button
              type="submit"
              className="btn btn-primary"
              disabled={
                !insuranceExcess ||
                !testDrive.disclaimer_agreed_at ||
                !testDrive.drivers_license_url
              }
            >
              Save
            </button>
          </>
        )}
        {!testDrive && dealership && <NewTestDrive onCreateTestDrive={onCreateTestDrive} />}

        <button onClick={handleBack} className="btn btn-link">
          Back
        </button>
      </form>
    </div>
  )
}
