import { useState } from 'react'
import Changes from './Changes'
import Distribution from './Distribution'
import DealerComments from './DealerComments'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

const CarTabs = ({ car, setCarSettingsVisible }) => {
  const [destinations, setDestinations] = useState([])

  return (
    <div className="box mb-3 position-relative lead-event-form">
      <Tabs>
        <div className="p-2 border-bottom">
          <TabList>
            <Tab>
              <i className="fa fa-info mr-2"></i>
              Details
            </Tab>
            <Tab>
              <i className="fa fa-upload mr-2"></i>
              Destinations
            </Tab>
            <Tab>
              <i className="fa fa-history mr-2"></i>
              Changes
            </Tab>
          </TabList>
        </div>
        <TabPanel>
          <div className="px-3 py-2">
            <DealerComments />
          </div>
          <hr />
          <div className="px-3 pb-2">
            <div className="mt-2">Features:
              {car.custom_features.map((feature, index) => {
                return (
                  <span key={index} className="badge badge-pill badge-success ml-1">{feature}</span>
                )
              })}
              <div className="btn btn-sm btn-outline-secondary ml-2" onClick={() => setCarSettingsVisible(true)}>
                <i className="fa fa-plus mr-1"></i>
                Features
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <Distribution car_id={car.id} destinations={destinations} setDestinations={setDestinations} />
        </TabPanel>
        <TabPanel>
          <Changes car={car} />
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default CarTabs