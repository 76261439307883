import moment from 'moment';
import { capitalise, time_ago } from '../entries/utils';
import LeadSourceBadge from '../leads/LeadSourceBadge'
import { LeadContext } from '../contexts'

const LeadBox = ({ leadClusters }) => (
  <div className="box mb-3">
    <div className="p-3">
      <h4 className='mb-1'><i className='fa fa-bolt mr-1'></i>Leads</h4>
    </div>
    <div className="table-responsive">
      <table className="table mb-0" style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
            <th>Source</th>
            <th>Created at</th>
          </tr>
        </thead>
        <tbody>
          {leadClusters.map((leadCluster) => (
            <tr key={`lead-${leadCluster.id}`}>
              <LeadContext.Provider value={{ leadCluster }}>
                <td>
                  <a
                    href={`/dealerships/${leadCluster.dealership_id}/lead_clusters/${leadCluster.id}`}
                    target="_blank">
                    {leadCluster.name}
                  </a>
                </td>
                <td>{capitalise(leadCluster.lead_state || '')}</td>
                <td><LeadSourceBadge leadSource={leadCluster.source} /></td>
                <td>
                  <div>{time_ago(leadCluster.created_at)}</div>
                  <div className="small text-secondary">{moment(leadCluster.created_at).format("DD/MM/YYYY")}</div>
                </td>
              </LeadContext.Provider>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
)

export default LeadBox