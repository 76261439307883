import { useContext } from 'react'
import { LeadClusterContext } from '../contexts'
const LeadSource = () => {
  const { leadCluster } = useContext(LeadClusterContext)

  let source = leadCluster?.source
  if (!source) return <></>
  return (
    <>
      {source === "Autotrader" && <div className="badge badge-success badge-autotrader">Autotrader</div>}
      {source === "Carsales" && <div className="badge badge-success badge-carsales">Carsales</div>}
      {source === "Facebook" && <div className="badge badge-success badge-facebook">Facebook</div>}
      {source === "Chatbot" && <div className="badge badge-success badge-chatbot">Chatbot</div>}
      {source === "Website" && <div className="badge badge-success">Website</div>}
      {source === "Drive" && <div className="badge badge-success badge-drive">Drive</div>}
      {source === "Facebook Marketplace" && <div className="badge badge-success badge-facebook">FB Marketplace</div>}
      {source === "Walk In" && <div className="badge badge-success">Walk In</div>}
      {source === "Phone Call" && <div className="badge badge-success">Phone Call</div>}
      {source === "Manufacturer" && <div className="badge badge-success">OEM</div>}
      {leadCluster.has_payment && <div className="badge badge-success badge-payment ml-1">Deposit</div>}
    </>
  )
}

export default LeadSource