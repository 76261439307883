
import { useState, useEffect, useContext } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import ItemRole from './ItemRole'
import { SplitButton } from 'primereact/splitbutton'
import { Dropdown } from 'primereact/dropdown'
import { Tag } from 'primereact/tag'
import { PrimeReactProvider } from 'primereact/api'
import { Paginator } from 'primereact/paginator'
import { truncateString, time_ago, humanize, getInitials } from '../entries/utils'
import { FilterMatchMode, FilterService } from 'primereact/api'
import DataTableSearch from '../entries/DataTableSearch'
import { UserService } from './userService'
import { StarRating } from '../shared/StarRating'
import * as Routes from '../../routes'
import SortableUsers from './SortableUsers'
import Loading from '../Loading'
import { CurrentUserContext, DealershipContext } from '../contexts'
import { useParams } from 'react-router-dom'
import { useFetchDealership } from '../dataHooks'

const customFilter = (value, filters) => {
  if (value === '' || (value !== '' && filters === '')) {
    return true
  }
  return value === filters
}

FilterService.register('custom_category', customFilter)


const Image = (user) => {
  const user_path = window.dealer_id ? Routes.dealership_user_path(window.dealer_id, user.id) : Routes.user_path(user.id)
  let initials = getInitials(user.name)
  return (
    <a href={user_path}>
      {user.image_url ? (
        <img
          src={user.image_url}
          alt={user.name}
          height={50}
          className="rounded-circle border"
        />
      ) : (
        <div className={`AvatarInitials ${initials[0]}`}>
          {initials}
        </div>
      )}
    </a>
  )
}

const Name = (user) => {
  const user_path = window.dealer_id ? Routes.dealership_user_path(window.dealer_id, user.id) : Routes.user_path(user.id)
  return (
    <div>
      <a href={user_path}>
        {truncateString(user.name, 30)}
      </a>
      <div className="small mt-2 text-secondary">
        {user.email}
      </div>
    </div>
  )
}

const Locations = (user) => {
  if (!user.locations) {
    return null
  }

  return (
    <>
      <div className="small">{user.displayed_locations}</div>
    </>
  )
}

const Category = (user) => {
  return (
    <>
      {user.category}
      <div className="small text-secondary">
        {user.position}
      </div>
    </>

  )
}

const Rating = (user) => {

  return (
    <div className="d-flex">
      <div>
        {user.rating} ({user.reviews_count})
      </div>
      <div className="ml-auto">
        <StarRating stars={user.rating} count={user.reviews_count} />
      </div>
    </div>
  )
}

const LastSignIn = (user) => {
  if (user.last_sign_in_at == 'Never') {
    return (
      <div>
        <div>{user.last_sign_in_at}</div>
      </div>
    )
  } else {
    return (
      <div>
        <div>{user.last_sign_in_at}</div>
        <div className="small text-secondary">{time_ago(user.last_sign_in_at)}</div>
      </div>
    )
  }
}

const Action = (user) => {
  const currentUser = useContext(CurrentUserContext)
  let showLoginAs = currentUser?.admin
  const user_path = window.dealer_id ? Routes.dealership_user_path(window.dealer_id, user.id) : Routes.user_path(user.id)
  const invite_user_path = window.dealer_id ? Routes.invite_dealership_user_path(window.dealer_id, user.id) : Routes.invite_user_path(user.id)
  const edit_user_path = window.dealer_id ? Routes.edit_dealership_user_path(window.dealer_id, user.id) : Routes.edit_user_path(user.id)

  const items = [
    {
      label: 'Resend Invite',
      icon: 'fa fa-envelope mr-2',
      command: () => {
        fetch(invite_user_path, {
          method: 'POST',
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          }
        }).then(() => {
          window.location.reload()
        })
      }
    },
    {
      label: 'Match Reviews',
      icon: 'fa fa-star mr-2',
      disabled: !window.dealer_id,
      command: () => {
        fetch(Routes.match_reviews_dealership_user_path(window.dealer_id, user.id), {
          method: 'POST',
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          }
        }).then(() => {
          window.location.reload()
        })
      }
    },

    {
      label: 'Remove Reviews',
      icon: 'fa fa-star mr-2',
      disabled: !window.dealer_id,
      command: () => {
        fetch(Routes.remove_reviews_dealership_user_path(window.dealer_id, user.id), {
          method: 'POST',
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          }
        }).then(() => {
          window.location.reload()
        })
      }
    },
    {
      label: 'Send Reset Password Email',
      icon: 'fa fa-envelope mr-2',
      disabled: !window.dealer_id,
      command: () => {
        fetch(Routes.send_reset_password_instructions_dealership_user_path(window.dealer_id, user.id), {
          method: 'POST',
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          }
        }).then(() => {
          window.location.reload()
        })
      }
    },
    {
      label: 'Login As',
      icon: 'fa fa-user mr-2',
      disabled: !showLoginAs,
      command: () => {
        fetch(Routes.impersonate_user_path(user.id), {
          method: 'POST',
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          }
        }).then(() => {
          window.location.reload()
        })
      }
    },
    {
      label: 'Remove from Dealership',
      icon: 'fa fa-trash',
      command: () => {
        const userConfirmation = window.confirm('Are you sure?')

        if (!userConfirmation) {
          return
        }
        fetch(user_path, {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          }
        }).then(() => {
          window.location.reload()
        })
      }
    }
  ]
  return (
    <div style={{ position: 'relative' }}>
      <SplitButton
        label="Edit"
        buttonProps={{ id: 'edit-button' }}
        onClick={() => { window.location.href = edit_user_path }}
        model={items}
        menuButtonProps={{ id: 'action-button' }}
        outlined
        rounded
      />
    </div>
  )
}

const Heading = ({ dealership }) => (
  <div className="clearfix">
    <div className="float-right">
      <div className="btn-group mb-3">
        <a href={`/dealerships/${dealership.slug}/users/new`} className="btn btn-success">
          <i className="fa fa-plus"></i> User
        </a>
        <button
          type="button"
          className="btn btn-outline-success dropdown-toggle"
          aria-haspopup="true"
          data-toggle="dropdown"
        />
        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
          <SortableUsers />
        </div>
      </div>
    </div>
    <h4>
      <>
        {dealership.name} Team
      </>
    </h4>
  </div>
)


const App = ({ categories = [], locations = [] }) => {

  const { dealership } = useContext(DealershipContext)

  useFetchDealership()

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    role: { value: null, matchMode: FilterMatchMode.EQUALS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    category: { value: '', matchMode: FilterMatchMode.CUSTOM },
    locations: { value: '', matchMode: FilterMatchMode.CONTAINS },
  })

  const getSeverity = (role) => {
    switch (role) {
      case 'Default':
        return 'danger'

      case 'Manager':
        return 'success'
    }
  }

  const [roles] = useState(['Manager', 'Default'])

  const roleItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverity(option)} />
  }

  const roleRowFilterTemplate = (options) => {
    return (
      <Dropdown value={options.value} options={roles} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={roleItemTemplate} placeholder="Select Role" className="p-column-filter" showClear style={{ maxWidth: '8rem' }} />
    )
  }

  const optionsMap = {
    'Category': categories.map(category => ({ label: humanize(category), value: category })),
    'Location': locations.map(location => ({ label: location, value: location }))
  }

  const categoryRowFilterTemplate = (options) => rowFilterTemplate(options, 'Category')
  const locationRowFilterTemplate = (options) => rowFilterTemplate(options, 'Location')

  const rowFilterTemplate = (options, name, width = '8rem') => {
    const selectedOptions = optionsMap[name]
    return (
      <Dropdown value={options.value} options={selectedOptions} onChange={(e) => options.filterApplyCallback(e.value)} placeholder={`Select ${name}`} className="p-column-filter" style={{ maxWidth: width }} />
    )
  }

  const onPageChange = (event) => {
    if (totalRecords != users.length) {
      event['filters'] = lazyState.filters
      setlazyState(event)
    }
  }


  const [totalRecords, setTotalRecords] = useState(0)
  const [users, setUsers] = useState(null)
  let [loading, setLoading] = useState(false)

  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 50,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      global: { value: null, matchMode: 'contains' }, // FilterMatchMode.CONTAINS is not used because lazyState was not updating
      role: { value: null, matchMode: 'equals' },
      name: { value: null, matchMode: 'contains' },
      category: { value: '', matchMode: 'custom_category' },
      locations: { value: '', matchMode: 'contains' }
    }
  })

  useEffect(() => {
    loadLazyData()
  }, [lazyState])

  const loadLazyData = () => {
    if (lazyState.page === undefined) {
      return
    }
    let page_number = 0
    if (lazyState.page === 0 || lazyState.first === 0) {
      page_number = 1
    } else {
      page_number = (lazyState.page + 1)
    }
    let params = { page: page_number, rows: lazyState.rows }
    let csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content")
    let dealershipId = window.dealer_slug || null
    try {
      setLoading(true)
      UserService.getUsers(params, csrf, dealershipId).then((data) => {
        setTotalRecords(data.data.total_records)
        setUsers(data.data.users)
        setLoading(false)
      })
    } catch (error) {
      console.error(error)
      setLoading(false)
    }

  }


  const header = DataTableSearch({ filters: lazyState.filters, setFilters: setFilters })

  return (
    <PrimeReactProvider>
      {dealership && <Heading dealership={dealership} />}
      <div className="box">
        {loading && <div className="p-4"><Loading /></div>}
        {users && (
          <DataTable
            value={users}
            paginator={totalRecords === users.length}
            rows={lazyState.rows}
            tableStyle={{ minWidth: '50rem' }}
            removableSort
            sortMode="multiple"
            scrollable
            globalFilterFields={['name', 'locations', 'role', 'category', 'email']}
            style={{ overflow: 'visible' }}
            wrapper={{ style: { overflow: 'visible' } }}
            filterDisplay='row'
            header={header}
            first={lazyState.first}
            totalRecords={totalRecords}
            filters={lazyState.filters}
          >
            <Column body={Image} field="image" header="Image" />
            <Column body={Name} filter field="name" header="Name" filterPlaceholder="Search by name" />
            <Column field="role" header="Role" sortable showFilterMenu={false} filterMenuStyle={{ width: '8rem' }} style={{ maxWidth: '8rem' }} body={ItemRole} filter filterElement={roleRowFilterTemplate} />
            <Column body={Locations} sortable field="locations" header="Location(s)" showFilterMenu={false} filterMenuStyle={{ width: '8rem' }} style={{ maxWidth: '8rem' }} filter filterElement={locationRowFilterTemplate} />
            <Column body={Category} sortable field="category" header="Category" showFilterMenu={false} filterMenuStyle={{ width: '8rem' }} style={{ maxWidth: '8rem' }} filter filterElement={categoryRowFilterTemplate} />
            <Column body={Rating} field="rating" style={{ maxWidth: '10rem' }} header="Google Rating" />
            <Column body={LastSignIn} field="last_sign_in_at" header="Last Login In" />
            <Column body={Action} header="Actions" />
          </DataTable>
        )}

        {users && totalRecords !== users.length && (
          <Paginator first={lazyState.first} rows={lazyState.rows} totalRecords={totalRecords} onPageChange={onPageChange} />
        )}
      </div>
    </PrimeReactProvider>
  )
}

const Wrapper = () => {
  let [loading, setLoading] = useState(false)
  let [params, setParams] = useState(false)
  let { dealershipSlug } = useParams()

  useEffect(() => {
    setLoading(true)
    let url = `/users/react_index.json`
    if (dealershipSlug) {
      url = `/dealerships/${dealershipSlug}/users/react_index.json`
    }

    fetch(url)
      .then(res => res.json())
      .then(data => {
        setParams(data)
        setLoading(false)
      })
  }, [])

  if (loading) {
    return (
      <div className="box m-4 w-100">
        <div className="p-4"><Loading /></div>
      </div>
    )
  }

  return (
    <div className="p-4 w-100">
      <App
        locations={params.locations}
        categories={params.categories}
      />
    </div>
  )
}

export default Wrapper