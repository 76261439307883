import { useState } from 'react';
import Select from 'react-select'

const
  OwnerSelect = ({ hit, selectedValue, onOwnerChange, disabled, className, users = false }) => {
    let [loading, setLoading] = useState(false)
    let usersForSelect = []
    if (users) {
      usersForSelect = users
    } else {
      usersForSelect = hit.available_users
    }

    let options = usersForSelect

    let locationSpecificUsers = usersForSelect.filter(u => u.location_ids?.includes(hit.location_id))

    if (hit.location_id && locationSpecificUsers.length > 0) {
      options = [
        {
          label: hit.location_name,
          options: locationSpecificUsers
        },
        {
          label: 'others',
          options: usersForSelect.filter(u => !u.location_ids?.includes(hit.location_id))
        }
      ]
    }

    const onChange = async ({ value }) => {
      setLoading(true)
      await onOwnerChange(hit.id, value);
      setLoading(false)
    }
    const currentValue = usersForSelect?.find(u => u.value === (selectedValue || hit.user_id))

    return (
      <Select
        options={options}
        name="user"
        isLoading={loading}
        value={currentValue}
        placeholder="Assign User"
        onChange={onChange}
        isDisabled={disabled}
        className={className || 'user-select'}
        menuPortalTarget={document.body}
      />
    )
  }

export default OwnerSelect
