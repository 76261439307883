import { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import Loading from '../Loading'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Subtitle } from './CarSearchItem'
import CarScore from './CarScore'
import Select from 'react-select'
import { humanize } from '../editor/common/Utils'
import { Image } from './AutograbIndex'

const Name = (rowData) => {

  return (
    <div>
      <div>
        <Link to={`/cars/${rowData.slug}`}>{rowData.name}</Link>
      </div>
      <small className="text-secondary">
        <Subtitle car={rowData} />
      </small>
    </div>
  )
}

const CarGrade = (rowData) => {

  if (!rowData.car_grade || !rowData.autograb_data?.car_grade) {
    return (
      <small className="text-secondary">
        Not Graded
      </small>
    )
  }

  return (
    <CarScore hit={rowData} />
  )
}

let options = ['used', 'new', 'demo'].map(o => ({ value: o, label: humanize(o) }))

const SmartPublisher = () => {
  let [stats, setStats] = useState(false)
  let [cars, setCars] = useState(false)
  let [loading, setLoading] = useState(false)
  let [carType, setCarType] = useState(options[0])

  let { dealershipSlug } = useParams()

  useEffect(() => {
    setLoading(true)
    fetch(`/dealerships/${dealershipSlug}/cars/smart_publisher.json?car_type=${carType.value}`)
      .then(res => res.json())
      .then(res => {
        setCars(res.cars)
        setStats(res.stats)
        setLoading(false)
      })
  }, [carType])

  return (
    <div className="p-3">
      <div className="d-flex mb-2">
        <h4>Smart Publisher</h4>
        <div className="ml-auto">
          <Select
            options={options}
            defaultValue={carType}
            onChange={newValue => setCarType(newValue)}
          />
        </div>
      </div>

      <div className="box">
        <div className="p-3">
          <h5>{carType.label} Cars</h5>
          {stats && (
            <div>
              <ul>
                <li>Total {carType.label} cars: {stats.total_cars}</li>
                <li>Listed on Autogate: {stats.cars_on_autogate}</li>
                <li>Not listed on Autogate: {stats.not_listed_on_autogate}</li>
                <li>Percentage with leads (all {carType.label} cars): {stats.lead_spread}%</li>
              </ul>
            </div>
          )}
          {loading && <Loading />}
        </div>
        <DataTable value={cars} loading={loading}>
          <Column sortable field="stocknum" header="Stocknum" />
          <Column body={Image} header="Photo" />
          <Column body={Name} field="name" header="Name" />
          <Column sortable field="days_old" header="Age" />
          <Column sortable field="leads_count" header="Leads count" />
          <Column sortable body={CarGrade} field="car_grade" header="Car Grade" />
        </DataTable>
      </div>
    </div>
  )
}

export default SmartPublisher