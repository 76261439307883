import { useEffect, useState } from "react"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Link } from "react-router-dom"

const Button = (rowData) => (
  <>
    <Link
      className="btn btn-outline-primary btn-sm mr-2"
      to="/stock_image_sets/new"
      state={{
        manufacturer_id: rowData.manufacturer.id,
        family_id: rowData.variant.family_id,
        year: rowData.year,
        badge: rowData.badge,
        simple_colour: rowData.simple_colour,
        bodyconfiguration: rowData.variant?.bodyconfiguration
      }}
    >
      Add Stock Image
    </Link>
    <Link className="btn btn-outline-primary btn-sm" to={`/cars/${rowData.slug}`}>
      View Car
    </Link>
  </>
)

const Model = (rowData) => (
  <>
    <div>{rowData.model}</div>
    <div className="small text-secondary">{rowData.variant?.bodyconfiguration}</div>
  </>
)

const ToDoCars = () => {

  let [data, setData] = useState([])
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    fetch('/stock_image_sets/cars_needing_stock_images.json')
      .then(res => res.json())
      .then(data => {
        setData(data)
        setLoading(false)
      })
  }, [])

  return (
    <div className="box mb-3">
      <div className="p-3">
        <h5>Cars which need stock images</h5>
        <div className="text-secondary">
          {data.total} cars currently need stock images added
        </div>
      </div>
      <DataTable value={data.cars} loading={loading}>
        <Column field="year" header="Year" />
        <Column field="manufacturer.name" header="Make" />
        <Column field="model" header="Model" body={Model} />
        <Column field="simple_colour" header="Colour" />
        <Column field={Button} header="Buttons" />
      </DataTable>
    </div>
  )
}

export default ToDoCars